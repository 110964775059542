import React, { useState } from "react";
import MenuSelect from "./MenuSelect";
import MenuCategories from "./MenuCategories";
import MenuItems from "./MenuItems";
import "./storeMenu.scss";
import ItemModal from "../itemModal/ItemModal";
import MenuSearch from "./MenuSearch";
import FilteredMenuItems from "./FilteredMenuItems";
import EditModal from "../itemModal/EditModal";
import { RxDropdownMenu } from "react-icons/rx";

function Menu(props) {
  const [filteredMenuItems, setFilteredMenuItems] = useState();
  async function toggleMenuList() {
    props.setOpenMenuList(!props.openMenuList);
  }

  /*

              <MenuSelect
                menuId={props.menuId}
                setMenuId={props.setMenuId}
                menuName={props.menuName}
                setMenuName={props.setMenuName}
                categoryName={props.categoryName}
                setCategoryName={props.setCategoryName}
                subCategoryName={props.subCategoryName}
                setSubCategoryName={props.setSubCategoryName}
              />
  */

  return (
    <div className="menu w-100">
      <div className="menu_list mt-1 mb-3">
        <div
          className="menu_list_button"
          id="menu_list_button_container"
          onClick={toggleMenuList}
        >
          <RxDropdownMenu id="menu_list_button" />
        </div>
        <div className="menu_select_container">
          <MenuSearch
            filteredMenuItems={filteredMenuItems}
            setFilteredMenuItems={setFilteredMenuItems}
          />
        </div>
      </div>
      
      {filteredMenuItems ? (
        <FilteredMenuItems
          filteredMenuItems={filteredMenuItems}
          setShowItemModal={props.setShowItemModal}
          setSelectedItem={props.setSelectedItem}
        />
      ) : (
        <>
          <MenuCategories
            setMenuName={props.setMenuName}
            menuName={props.menuName}
            menuId={props.menuId}
            categoryName={props.categoryName}
            setCategoryName={props.setCategoryName}
            subCategoryName={props.subCategoryName}
            setSubCategoryName={props.setSubCategoryName}
          />
          <MenuItems
            menuName={props.menuName}
            menuId={props.menuId}
            categoryName={props.categoryName}
            subCategoryName={props.subCategoryName}
            setShowItemModal={props.setShowItemModal}
            setSelectedItem={props.setSelectedItem}
          />
        </>
      )}

      {props.showItemModal ? (
        <ItemModal
          showItemModal={props.showItemModal}
          setShowItemModal={props.setShowItemModal}
          selectedItem={props.selectedItem}
          setAppContext={props.setAppContext}
        />
      ) : null}
      {props.showEditItemModal ? (
        <EditModal
          showItemModal={props.showEditItemModal}
          setShowItemModal={props.setShowEditItemModal}
          selectedItem={props.selectedItem}
          setCartOpen={props.setCartOpen}
          setAppContext={props.setAppContext}
        />
      ) : null}
    </div>
  );
}

export default Menu;
