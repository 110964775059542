export const formatTime = (time) => {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatEST = (time) => {
  var hours = time.getHours();
  const minutes = time.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}:00.00Z`;
};

export const formatShortTime = (time) => {
  const [hoursMinutes, ampm] = time.split(" ");
  const [hours, minutes] = hoursMinutes.split(":");

  // Convert hours and minutes to numbers
  var hoursNum = parseInt(hours, 10);
  var minutesNum = parseInt(minutes, 10);

  // If it's PM and hours are less than 12, add 12 to hours
  if (ampm.toLowerCase() === "pm" && hoursNum < 12) {
    hoursNum += 12;
  }

  // If it's AM and hours are 12, set hours to 0
  if (ampm.toLowerCase() === "am" && hoursNum === 12) {
    hoursNum = 0;
  }

  // Format the time as a string
  return `${hoursNum.toString().padStart(2, "0")}:${minutesNum
    .toString()
    .padStart(2, "0")}`;
};
