export const editCartValues = (state, newItem) => {
  var extraTotal = 0;
  for (var i = 0; i < newItem.Extras.length; i++) {
    extraTotal = parseFloat(extraTotal) + parseFloat(newItem.Extras[i].ExtraPrice);
  }
  var num =
    state.cart.CartPrice +
    parseFloat(
      ((newItem.ItemPrice + extraTotal) * newItem.ItemQuantity).toFixed(2)
    );
  const fixedNumber = parseFloat(num);
  var cartData = {
    CartID: state.userInfo.userID,
    OrderType: state.cart.OrderType,
    CartPrice: fixedNumber,
  };

  return cartData;
};
