import React, { useState, useContext, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import StoreMenu from "../../components/menu/StoreMenu";
import ApplicationContext from "../../ApplicationContext";
import Cart from "../../components/cart/Cart";
import Footer from "../../components/footer/Footer";
import RecentOrders from "../../components/account/RecentOrders";
import {
  APPLICATION,
  BASE_API,
  STORE_NAME,
  STORE_NAME_CODE,
} from "../../constants";
import axios from "axios";
import { formatOrdersData } from "../../utils/orders/FormatUserOrders";
import { toast } from "react-toastify";
import CartItems from "../../components/cart/CartItems";
import { useNavigate } from "react-router-dom";
import StoreContext from "../../StoreContext";
import { Helmet } from "react-helmet";
import MenuList from "../../components/menu/MenuList";
import { BsArrowRightSquareFill } from "react-icons/bs";
import { MdArrowRight } from "react-icons/md";

function Home(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);
  const navigate = useNavigate();

  const [showWelcome] = useState(true);
  const [userOrders, setUserOrders] = useState();
  const [openMenuList, setOpenMenuList] = useState(false);
  const [menuId, setMenuId] = useState(2);

  useEffect(() => {
    if (storeContext.rewardsProgram === "true") {
      var line = document.getElementById("line");
      if (line) {
        line.style.backgroundColor = storeContext.color;
      }
    }
  }, []);

  useEffect(() => {
    const fetchUserRecentOrders = async () => {
      try {
        const response = await axios.get(
          `${BASE_API}api/User/GetUserMostRecentOrders/${appContext.userInfo.userID}`,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );
        var orders = formatOrdersData(response.data);
        setUserOrders(orders);
      } catch (error) {
        if (error.response.status === 401) {
          var newAppContext = {
            userInfo: null,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
          toast.error("Your session has expired please login again.");
        }
      }
    };
    if (!userOrders && APPLICATION && appContext.userInfo) {
      fetchUserRecentOrders();
    }
  }, [appContext, props, userOrders]);

  function checkout() {
    if(storeContext.mxMerchant === "true") {
      if (
        !appContext.userInfo.role ||
        !appContext.userInfo.firstName ||
        appContext.userInfo.role === "Guest"
      ) {
        props.setAuthKey("Guest");
        props.setShowAuthenticateModal(true);
      } else if (appContext.cart.CartPrice >= storeContext.orderMin) {
        navigate("order");
      } else {
        toast.error(`Mimimum order amount is $${storeContext.orderMin}.`);
      }
    } else {
      if (
        !appContext.userInfo.role ||
        !appContext.userInfo.email ||
        !appContext.userInfo.firstName ||
        appContext.userInfo.role === "Guest"
      ) {
        props.setAuthKey("Guest");
        props.setShowAuthenticateModal(true);
      } else if (appContext.cart.CartPrice >= storeContext.orderMin) {
        navigate("order");
      } else {
        toast.error(`Mimimum order amount is $${storeContext.orderMin}.`);
      }
    }
  }

  return (
    <>
      <Navbar
        showAuthenticateModal={props.showAuthenticateModal}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
        setAuthKey={props.setAuthKey}
        setAppContext={props.setAppContext}
        discountEndDate={props.discountEndDate}
        showWelcome={showWelcome}
      />
      <main>
        <div className="main_container">
          {openMenuList && (
            <MenuList
              openMenuList={openMenuList}
              setOpenMenuList={setOpenMenuList}
              menuId={menuId}
              setMenuId={setMenuId}
              menuName={props.menuName}
              setMenuName={props.setMenuName}
              categoryName={props.categoryName}
              setCategoryName={props.setCategoryName}
              subCategoryName={props.subCategoryName}
              setSubCategoryName={props.setSubCategoryName}
            />
          )}

          {storeContext.rewardsProgram === "true" && (
            <div
              className="rewards_container mx-2 mt-1 mb-2"
              id="rewards_container"
            >
              <a href={`${STORE_NAME_CODE}/rewards`}>
                <div className="line" id="line"></div>
                <div className="text_container">
                  <div className="text">
                    <div className="reward_top">
                      {STORE_NAME} Loyalty Program
                    </div>
                    <div className="reward_bottom">
                      Earn points to unlock bonus offers & get exclusive
                      discounts for your next order!
                    </div>
                    <div className="reward_bottom">
                      <b>Every $1 spent = 1 Reward Point!</b>
                    </div>
                  </div>
                  <MdArrowRight />
                </div>
              </a>
            </div>
          )}

          <RecentOrders
            userOrders={userOrders}
            setAppContext={props.setAppContext}
          />

          <StoreMenu
            menuName={props.menuName}
            setMenuName={props.setMenuName}
            categoryName={props.categoryName}
            setCategoryName={props.setCategoryName}
            subCategoryName={props.subCategoryName}
            setSubCategoryName={props.setSubCategoryName}
            showItemModal={props.showItemModal}
            setShowItemModal={props.setShowItemModal}
            showEditItemModal={props.showEditItemModal}
            setShowEditItemModal={props.setShowEditItemModal}
            selectedItem={props.selectedItem}
            setSelectedItem={props.setSelectedItem}
            setCartOpen={props.setCartOpen}
            setAppContext={props.setAppContext}
            setOpenMenuList={setOpenMenuList}
            openMenuList={openMenuList}
            menuId={menuId}
            setMenuId={setMenuId}
          />

          {appContext.cartItems && (
            <>
              {appContext.cartItems.length >= 1 && (
                <Cart
                  detailsAreLoading={props.detailsAreLoading}
                  cartOpen={props.cartOpen}
                  setCartOpen={props.setCartOpen}
                  setShowAuthenticateModal={props.setShowAuthenticateModal}
                  showEditItemModal={props.showEditItemModal}
                  setShowEditItemModal={props.setShowEditItemModal}
                  selectedItem={props.selectedItem}
                  setSelectedItem={props.setSelectedItem}
                  setAppContext={props.setAppContext}
                  setAuthKey={props.setAuthKey}
                />
              )}
            </>
          )}
        </div>
        <div className="desktop_cart_container">
          <div className="desktop_cart">
            <div className="title">My Cart</div>
            <div className="cart_body">
              {appContext.cartItems ? (
                <>
                  {appContext.cartItems.length > 0 ? (
                    <>
                      <CartItems
                        isDisabled={props.isDisabled}
                        setIsDisabled={props.setIsDisabled}
                        setSelectedItem={props.setSelectedItem}
                        setShowEditItemModal={props.setShowEditItemModal}
                        setCartOpen={props.setCartOpen}
                        setAppContext={props.setAppContext}
                      />
                      <div
                        className="checkout_container"
                        id="checkout_container"
                        onClick={checkout}
                      >
                        <p className="total">
                          Total: ${appContext.cart.CartPrice.toFixed(2)}
                        </p>
                        <div className="checkout_text">
                          <p className="text">Checkout</p>
                          <BsArrowRightSquareFill />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="empty">
                      <img src="/pictures/emptycart.png" alt="" />
                    </div>
                  )}
                </>
              ) : (
                <div className="empty">
                  <img src="/pictures/emptycart.png" alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />

      <Helmet>
        <title>{`${storeContext.name}`} - Order Online</title>
        <link
          rel="icon"
          type="image/png"
          href={`/pictures/${storeContext.nameCode}.png`}
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href={`/pictures/${storeContext.nameCode}.png`}
          sizes="32x32"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/pictures/${storeContext.nameCode}.png`}
        />
        <meta
          property="title"
          content={`${storeContext.name} - Order Online`}
        />
        <meta name="description" content={`${storeContext.seoDescription}`} />
        <meta name="keywords" content={`${storeContext.seoKeywords}`} />
        <meta name="author" content={`${storeContext.name}`} />

        <meta
          property="og:title"
          content={`${storeContext.name} - Order Online`}
        />
        <meta
          property="og:description"
          content={`${storeContext.seoDescription}`}
        />
        <meta property="og:image" content={`${storeContext.seoImage}.png`} />
        <meta property="og:url" content={`${storeContext.seoLink}`} />

        <meta
          name="twitter:title"
          content={`${storeContext.name} - Order Online`}
        />
        <meta
          name="twitter:description"
          content={`${storeContext.seoDescription}`}
        />
        <meta name="twitter:image" content={`${storeContext.seoImage}.png`} />
        <meta name="twitter:card" content={`${storeContext.name}`} />

        <link rel="canonical" href={`${storeContext.seoLink}`} />
      </Helmet>
    </>
  );
}

export default Home;
