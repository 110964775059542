export const getNowTime = () => {
  const now = new Date();

  // Function to check if current date is in DST
  const isDST = () => {
    const year = now.getFullYear();

    // DST starts on the second Sunday in March
    const dstStart = new Date(year, 2, 1); // Start with March 1st
    dstStart.setDate(1 + (7 - dstStart.getDay()) % 7 + 7); // Adjust to second Sunday

    // DST ends on the first Sunday in November
    const dstEnd = new Date(year, 10, 1); // Start with November 1st
    dstEnd.setDate(1 + (7 - dstEnd.getDay()) % 7); // Adjust to first Sunday

    return now >= dstStart && now < dstEnd;
  };

  const estOffset = isDST() ? -4 * 60 : -5 * 60; // Adjust for DST (UTC offset)
  const utcDate = new Date(now.getTime() + estOffset * 60 * 1000); // Adjust the time to EST

  return utcDate.toISOString(); // Return in ISO format
};
