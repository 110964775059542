import React, { useContext, useState, useEffect } from "react";
import ApplicationContext from "../../../ApplicationContext";
import StoreContext from "../../../StoreContext";
import { toast } from "react-toastify";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../../constants";

function OrderETA(props) {
  const storeContext = useContext(StoreContext);
  const appContext = useContext(ApplicationContext);

  const [pickupMin, setPickupMin] = useState();
  const [pickupMax, setPickupMax] = useState();
  const [deliveryMin, setDeliveryMin] = useState();
  const [deliveryMax, setDeliveryMax] = useState();

  function handleChange(e, type) {
    if (type === "PickupMin") {
      const inputValue = e.target.value;
      setPickupMin(() => {
        // Convert the string to an integer using parseInt()
        const intValue = parseInt(inputValue, 10);
        return intValue;
      });
    }

    if (type === "PickupMax") {
      const inputValue = e.target.value;
      setPickupMax(() => {
        // Convert the string to an integer using parseInt()
        const intValue = parseInt(inputValue, 10);
        return intValue;
      });
    }

    if (type === "DeliveryMin") {
      const inputValue = e.target.value;
      setDeliveryMin(() => {
        // Convert the string to an integer using parseInt()
        const intValue = parseInt(inputValue, 10);
        return intValue;
      });
    }

    if (type === "DeliveryMax") {
      const inputValue = e.target.value;
      setDeliveryMax(() => {
        // Convert the string to an integer using parseInt()
        const intValue = parseInt(inputValue, 10);
        return intValue;
      });
    }
  }

  async function handleSubmit() {
    const storeId = 1;
    if (!pickupMin || !pickupMax || !deliveryMin || !deliveryMax) {
      toast.error("Please make sure all times are filled in.");
      return;
    }
    if (pickupMin > pickupMax) {
      toast.error(
        "Your PICKUP Minimum Time can not be more than Maximum Time."
      );
      return;
    }
    if (deliveryMin > deliveryMax) {
      toast.error(
        "Your DELIVERY Minimum Time can not be more than Maximum Time."
      );
      return;
    }
    try {
      await axios.put(
        `${BASE_API}ChangeOrderETAs/${storeId}/${pickupMin}/${pickupMax}/${deliveryMin}/${deliveryMax}`,
        {
          storeId,
          pickupMin,
          pickupMax,
          deliveryMin,
          deliveryMax,
        },
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );
      toast.success("Updated estimaed times");
    } catch (error) {
      toast.error("Please try again");
    }
  }

  useEffect(() => {
    if (!pickupMin || !pickupMax || !deliveryMin || !deliveryMax) {
      var delivery = storeContext.deliveryETA;
      const regex = /\d+/g;
      const numbers = delivery.match(regex);
      setDeliveryMin(numbers[0]);
      setDeliveryMax(numbers[1]);

      var pickup = storeContext.pickupETA;
      const nums = pickup.match(regex);
      setPickupMin(nums[0]);
      setPickupMax(nums[1]);
    }
  }, []);

  return (
    <div className="orderETA_container">
      <div className="card-body d-flex flex-column">
        <div className="order_eta">
          <div className="ETA">
            <div className="ETA_title">Set Pickup Estimate</div>
            <div className="ETA_inputs">
              <input
                type="number"
                required
                maxLength="2"
                onChange={(e) => handleChange(e, "PickupMin")}
                placeholder={pickupMin}
              />
              <div className="hyphen"> - </div>
              <input
                type="number"
                required
                maxLength="2"
                onChange={(e) => handleChange(e, "PickupMax")}
                placeholder={pickupMax}
              />
              <div className="minutes">minutes</div>
            </div>
          </div>

          <div className="ETA">
            <div className="ETA_title">Set Delivery Estimate</div>
            <div className="ETA_inputs">
              <input
                type="number"
                required
                maxLength="2"
                onChange={(e) => handleChange(e, "DeliveryMin")}
                placeholder={deliveryMin}
              />
              <div className="hyphen"> - </div>
              <input
                type="number"
                required
                maxLength="2"
                onChange={(e) => handleChange(e, "DeliveryMax")}
                placeholder={deliveryMax}
              />
              <div className="minutes">minutes</div>
            </div>
          </div>
        </div>
        <div className="submit_container">
          <div onClick={handleSubmit} className="submit">
            Submit
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderETA;
