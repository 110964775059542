export const updateCartItemsAfterDeleteItem = (state, deleteItem) => {
  for (var i = 0; i < state.cartItems.length; i++) {
    if (state.cartItems[i].CartItemID === deleteItem.CartItemID) {
      state.cartItems.splice(i, 1);
    }
  }

  return state.cartItems;
};

export const updateCartAfterDeleteItem = (state, newPrice) => {
  const fixedNumber = parseFloat(newPrice.toFixed(2));

  var cartData = {
    CartID: state.userInfo.userID,
    OrderType: state.cart.OrderType,
    CartPrice: fixedNumber,
  };

  return cartData;
};
