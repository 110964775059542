export const formatUsersCart = (response) => {
    var loaded_cart = {
      CartID: response.data.Cart[0].CartID,
      CartPrice: response.data.Cart[0].CartPrice,
      OrderType: response.data.Cart[0].OrderType,
    };
  
    var loaded_cartItems = [];
    for (var i = 0; i < response.data.CartItems.length; i++) {
      var item = {
        CartItemID: response.data.CartItems[i].CartItemID,
        CartItem_CartID: response.data.CartItems[i].CartItem_CartID,
        CartItem_MenuItemID: response.data.CartItems[i].CartItem_MenuItemID,
        ItemCategory: response.data.CartItems[i].ItemCategory,
        ItemDescription: response.data.CartItems[i].ItemDescription,
        ItemName: response.data.CartItems[i].ItemName,
        ItemPrice: response.data.CartItems[i].ItemPrice,
        ItemQuantity: response.data.CartItems[i].ItemQuantity,
        ItemSpecialRequests: response.data.CartItems[i].ItemSpecialRequests,
        ItemSubCategory: response.data.CartItems[i].ItemSubCategory,
        ItemTotalPrice: response.data.CartItems[i].ItemTotalPrice,
        MenuID: response.data.CartItems[i].MenuID,
        MenuItemID: response.data.CartItems[i].MenuItemID,
        SizeCode: response.data.CartItems[i].SizeCode,
        RewardCost: response.data.CartItems[i].RewardCost,
      };
      item.Extras = [];
      for (var j = 0; j < response.data.Extras.length; j++) {
        if (
          response.data.CartItems[i].CartItemID ===
          response.data.Extras[j].CartExtra_CartItemID
        ) {
          var sameItem = {
            CartExtra_CartID: response.data.Extras[j].CartExtra_CartID,
            CartExtra_CartItemID:
              response.data.Extras[j].CartExtra_CartItemID,
            CartExtra_MenuItemExtraID:
              response.data.Extras[j].CartExtra_MenuItemExtraID,
            CartItemExtraID: response.data.Extras[j].CartItemExtraID,
            ExtraDescription: response.data.Extras[j].ExtraDescription,
            ExtraName: response.data.Extras[j].ExtraName,
            ExtraPrice: response.data.Extras[j].ExtraPrice,
            MenuItemExtraID: response.data.Extras[j].MenuItemExtraID,
            PizzaFirstHalf: response.data.Extras[j].PizzaFirstHalf,
            PizzaSecondHalf: response.data.Extras[j].PizzaSecondHalf,
          };
          if (
            response.data.Extras[j].PizzaFirstHalf === 1 &&
            response.data.Extras[j].PizzaSecondHalf !== 1
          ) {
            sameItem.PizzaHalfCode = "(1st Half)";
          } else if (
            response.data.Extras[j].PizzaSecondHalf === 1 &&
            response.data.Extras[j].PizzaFirstHalf !== 1
          ) {
            sameItem.PizzaHalfCode = "(2nd Half)";
          } else {
            sameItem.PizzaHalfCode = null;
          }
          item.Extras.push(sameItem);
        }
      }
      loaded_cartItems.push(item);
    }
  
    return {loaded_cart, loaded_cartItems};
  
  }