import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import StoreContext from "../../StoreContext";
import "./locationModal.scss";

function LocationModal(props) {
  const storeContext = useContext(StoreContext);

  async function closeLocationModal() {
    props.setShowLocationModal(false);
  }
  return (
    <>
      <Modal className="hoursModal" show={props.showLocationModal}>
        <Modal.Body>
          <Modal.Header>
            <FaWindowClose
              onClick={closeLocationModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          <div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LocationModal;
