import React, { useContext, useEffect, useState } from "react";
import { FcSearch } from "react-icons/fc";
import MenuContext from "../../MenuContext";

function MenuSearch(props) {
  const menuContext = useContext(MenuContext);
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle input field changes
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Use useEffect to update the filtered items when searchQuery changes
  useEffect(() => {
    if (menuContext.items) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filteredItems = menuContext.items.filter((item) => {
        const { itemCategory, itemSubCategory, itemDescription, itemName } =
          item;
        return (
          itemCategory?.toLowerCase().includes(lowerCaseQuery) ||
          "" ||
          itemSubCategory?.toLowerCase().includes(lowerCaseQuery) ||
          "" ||
          itemDescription?.toLowerCase().includes(lowerCaseQuery) ||
          "" ||
          itemName?.toLowerCase().includes(lowerCaseQuery) ||
          ""
        );
      });
      if (searchQuery) {
        props.setFilteredMenuItems(filteredItems);
      } else {
        props.setFilteredMenuItems();
      }
    }
  }, [searchQuery]);

  return (
    <div className="menu_search d-flex flex-row">
      <FcSearch />
      <input
        value={searchQuery}
        onChange={handleSearchInputChange}
        placeholder="Search menu keywords ..."
      />
    </div>
  );
}

export default MenuSearch;
