import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { APPLICATION, BASE_API } from "../../constants";
import ApplicationContext from "../../ApplicationContext";
import { toast } from "react-toastify";
import EditOrderModal from "./editOrderModal/EditOrderModal";
import Loading from "../../tools/Loading";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

function OrderHistory(props) {
  const appContext = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);

  const [showEditOrderScreen, setShowEditOrderScreen] = useState(false);
  const [orderItemsData, setOrderItemsData] = useState();
  const [orderData, setOrderData] = useState();
  const [orders, setOrders] = useState();

  const [paidAlreadyTotal, setPaidAlreadyTotal] = useState();
  const [paidLaterTotal, setPaidLaterTotal] = useState();

  const [cancelledOrdersNum, setCancelledOrdersNum] = useState();
  const [ordersNum, setOrdersNum] = useState();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const selectedMonthName = monthNames[props.selectedMonth - 1];
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const monthContainer = document.querySelector('.month_container');
    if (monthContainer) {
      const selectedDiv = monthContainer.querySelector('.selected');

      if (selectedDiv) {
        monthContainer.scrollLeft = selectedDiv.offsetLeft;
      }
    }
  }, []);

  async function getOrderData(i) {
    try {
      const response = await axios.get(
        `${BASE_API}api/AndroidOrders/GetOrderItemsAndExtras/${i.orderID}`,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );
      setOrderItemsData(response.data);
      setOrderData(i);  
    } catch (error) {
      toast.error("Could not get orders.");
    }

    setShowEditOrderScreen(!showEditOrderScreen);
  }

  const handleMonthClick = (month, year) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 250);

    setOrders(getOrdersByMonthAndYear(month, year));

    props.setSelectedMonth(month);
    props.setSelectedYear(year);
    setSelectedWeek(null);
  };

  // Function to filter orders by month and year
  const getOrdersByMonthAndYear = (month, year) => {
    return props.orderHistory.filter((order) => {
      const orderedDate = new Date(order.orderedDate);
      const orderMonth = orderedDate.getMonth() + 1; // January is 0
      const orderYear = orderedDate.getFullYear();

      return orderMonth === month && orderYear === year;
    });
  };

  useEffect(() => {
    if (orders) {
      var totalCharged = 0;
      var totalPayedLater = 0;
      var cancelled = 0;
      var totalNumOrders = 0;
      for (var i = 0; i < orders.length; i++) {
        if (orders[i].discountedTotal) {
          if (orders[i].payLater === "true") {
            totalPayedLater = totalPayedLater + orders[i].discountedTotal;
          } else {
            if (orders[i].otherTotal) {
              totalCharged = totalCharged + orders[i].otherTotal;
            } else {
              totalCharged = totalCharged + orders[i].discountedTotal;
            }
          }
        } else {
          if (orders[i].payLater === "true") {
            totalPayedLater = totalPayedLater + orders[i].orderTotal;
          } else {
            if (orders[i].otherTotal) {
              totalCharged = totalCharged + orders[i].otherTotal;
            } else {
              totalCharged = totalCharged + orders[i].orderTotal;
            }
          }
        }

        if (orders[i].cancelledDate) {
          cancelled = cancelled + 1;
          if (orders[i].discountedTotal) {
            totalCharged = totalCharged - orders[i].discountedTotal;
          } else {
            totalCharged = totalCharged - orders[i].orderTotal;
          }
        } else {
          totalNumOrders = totalNumOrders + 1;
        }
      }
      setPaidAlreadyTotal(totalCharged.toFixed(2));
      setPaidLaterTotal(totalPayedLater.toFixed(2));
      setCancelledOrdersNum(cancelled);
      setOrdersNum(totalNumOrders);
    }
  }, [orders]);

  const handleDecrementYear = () => {
    setSelectedYear((prevYear) => prevYear - 1);
  };

  const handleIncrementYear = () => {
    setSelectedYear((prevYear) => prevYear + 1);
  };

  useEffect(() => {
    if (props.selectedMonth && props.selectedYear && props.orderHistory) {
      setOrders(
        getOrdersByMonthAndYear(props.selectedMonth, props.selectedYear)
      );
    }
  }, [props.orderHistory]);

  return (
    <>
      <div className="admin_tabs_content orderHistory_container">
        {showEditOrderScreen && (
          <EditOrderModal
            showEditOrderScreen={showEditOrderScreen}
            setShowEditOrderScreen={setShowEditOrderScreen}
            orderData={orderData}
            orderItemsData={orderItemsData}
            getOrderHistory={props.getOrderHistory}
          />
        )}

        {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <div className="month_selection">
              <div className="year_container">
                <FaAngleLeft onClick={handleDecrementYear} />
                <div className="year">{selectedYear}</div>
                <FaAngleRight onClick={handleIncrementYear} />
              </div>
              <div className="month_container">
                {monthNames.map((month, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => handleMonthClick(index + 1, selectedYear)}
                      style={{ marginRight: "2px" }}
                      className={
                        month === selectedMonthName &&
                        selectedYear === props.selectedYear
                          ? "monthButton selected"
                          : "monthButton"
                      }
                    >
                      <div className="month d-block">
                        <div className="label">{month}</div>
                        <div className="label">{selectedYear}</div>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="order_summary">
              <div className="selectedMonth fw-bold">
                {selectedWeek ? (
                  <u>{selectedWeek} Summary</u>
                ) : (
                  <u>
                    {selectedMonthName} {props.selectedYear} Summary
                  </u>
                )}
              </div>
              {orders && (
                <div className="numberOfOrders">
                  <div>
                    <b>{ordersNum}</b> Orders
                  </div>
                  {cancelledOrdersNum > 0 && (
                    <div style={{ marginLeft: "5px", paddingLeft: "5px", borderLeft: "2px solid black" }}>
                      <b>{cancelledOrdersNum}</b> Cancelled Orders
                    </div>
                  )}
                </div>
              )}
              <div className="chargedNumbers">
                {paidAlreadyTotal > 0 && (
                  <div className="d-flex chargedAmount">
                    Total Charged Online:
                    <b className="mx-1">${paidAlreadyTotal}</b>
                  </div>
                )}
                {paidLaterTotal > 0 && (
                  <div className="d-flex chargedAmount rightSide">
                    Total Paid Later:
                    <b className="mx-1">${paidLaterTotal}</b>
                  </div>
                )}
              </div>
            </div>

            {orders?.map((i) => (
              <div
                className="card"
                key={i.orderID}
                onClick={() => getOrderData(i)}
              >
                <div className="card-header">
                  <div className="left">
                    <div className="orderNumber">#{i.orderNumber}</div>
                    <div className="orderType">{i.orderType} |</div>
                    <div className="orderName">{i.name} |</div>
                    {i.cancelledDate ? (
                      <div className="cancelled total">$0.00</div>
                    ) : (
                      <>
                        {i.discountedTotal ? (
                          <>
                            {i.otherTotal ? (
                              <div className="edited total">
                                ${i.otherTotal}
                              </div>
                            ) : (
                              <div className="total">${i.discountedTotal.toFixed(2)}</div>
                            )}
                          </>
                        ) : (
                          <>
                            {i.otherTotal ? (
                              <div className="edited total">
                                ${i.otherTotal}
                              </div>
                            ) : (
                              <div className="total">${i.orderTotal.toFixed(2)}</div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <div className="requestedDate">
                      | {new Date(i.requestedDate).toLocaleString()}
                    </div>
                  </div>
                  <div className="right">
                    {i.payLater && (
                      <div className="status_tag pay_later">PAY LATER</div>
                    )}
                    {i.completedDate && (
                      <div className="status_tag complete">COMPLETED</div>
                    )}
                    {i.cancelledDate && (
                      <div className="status_tag cancelled">CANCELLED</div>
                    )}
                    {i.isEdited && (
                      <div className="status_tag edited">CHARGED</div>
                    )}
                    {i.isVoided && (
                      <div className="status_tag edited">VOIDED</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default OrderHistory;
