export const checkIfStoreIsOpen = (hours, closedDays) => {
  if (hours && closedDays) {
    var status = "Closed"; // Default to closed

    // Get the current day and time
    var now = new Date();
    var currentDay = now.toLocaleString("en-US", { weekday: "long" });
    var currentTime =
      now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

    // Find the entry for the current day in the hours array
    var currentDayEntry = hours.find((entry) => entry.dayOfWeek === currentDay);

    // Check if today is in the list of closed days
    var isClosedDay = closedDays.some((closedDay) => {
      var closedDate = new Date(closedDay.dateClosed);
      return (
        closedDate.getDate() === now.getDate() &&
        closedDate.getMonth() === now.getMonth() &&
        closedDate.getFullYear() === now.getFullYear()
      );
    });

    if (!isClosedDay && currentDayEntry) {
      // Extract open and close times for the current day
      var openTimeParts = currentDayEntry.openTime.split(":").map(Number);
      var closeTimeParts = currentDayEntry.closeTime.split(":").map(Number);

      // Convert open and close times to seconds since midnight
      var openTimeInSeconds =
        openTimeParts[0] * 3600 + openTimeParts[1] * 60 + openTimeParts[2];
      var closeTimeInSeconds =
        closeTimeParts[0] * 3600 + closeTimeParts[1] * 60 + closeTimeParts[2];

      // If close time is earlier than open time, consider it on the next day
      if (closeTimeInSeconds < openTimeInSeconds) {
        closeTimeInSeconds += 24 * 3600; // add 24 hours in seconds
      }

      // Check if the current time is within the open and close times
      if (
        currentTime >= openTimeInSeconds &&
        currentTime <= closeTimeInSeconds
      ) {
        status = "Open";
      }
    }

    return status;
  }
};
