import React from "react";

function SystemHome() {
  return (
    <div className="app not_found">
      <div className="store_container my-4">
        <div className="fs-3 fw-bold">Are you looking for more options?</div>
        <div className="store_link_container">
          <a className="store card my-1 p-1 fs-7" href="/frankspizzaoakridge">
            <div>Franks Pizza - Oak Ridge, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/somersetpizza">
            <div>Somerset Pizza - Somerset, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/brendaleedover">
            <div>Brenda Lee Restaurant - Dover, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/littleitalybrickoven">
            <div>Little Italy Brick Oven Pizza & Cafe - Washington, NJ</div>
          </a>
          <a
            className="store card my-1 p-1 fs-7"
            href="/annabelspizzaunionville"
          >
            <div>Annabels Pizza & Italian Restaurant - Unionville, NY</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/pezzopizzauno">
            <div>Pezzo Pizza Uno - Newton, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/pizzarusticarockaway">
            <div>Pizza Rustica - Rockaway, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/lacasadeltaco">
            <div>La Casa Del Taco - Hackettstown, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/frankspizzablairstown">
            <div>Franks Pizza - Blairstown, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/rivolipizzeriaunion">
            <div>Rivoli Pizzeria - Union, NJ</div>
          </a>
          <a className="store card my-1 p-1 fs-7" href="/vincenzopizzabuddlake">
            <div>Vincenzo Pizzeria - Budd Lake, NJ</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SystemHome;
