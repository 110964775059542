import React, { useState, useContext } from "react";
import { Context } from "../../ApplicationContext";
import { toast } from "react-toastify";
import axios from "axios";
import ApplicationContext from "../../ApplicationContext";
import { APPLICATION, BASE_API } from "../../constants";

function UserAccountDetails(props) {
  const appContext = useContext(ApplicationContext);

  const [firstName, setFirstName] = useState(appContext.userInfo.firstName);
  const [lastName, setLastName] = useState(appContext.userInfo.lastName);
  const [email, setEmail] = useState(appContext.userInfo.email);
  const [phoneNumber, setPhoneNumber] = useState(appContext.userInfo.phoneNumber);

  var editedUserData = {
    FirstName: firstName,
    LastName: lastName,
    Email: email,
    PhoneNumber: phoneNumber,
  };

  async function editUser() {
    var newAppContext;
    if (
      firstName !== appContext.userInfo.firstName ||
      lastName !== appContext.userInfo.lastName ||
      email !== appContext.userInfo.email ||
      phoneNumber !== appContext.userInfo.phoneNumber
    ) {
      try {
        await axios.put(
          `${BASE_API}api/User/EditMyAccount`,
          editedUserData,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );
        var newUserInfo = {
          customerID: appContext.userInfo.customerID,
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          role: appContext.userInfo.role,
          token: appContext.userInfo.token,
          userID: appContext.userInfo.userID,
          storeName: appContext.userInfo.storeName,
        };
        newAppContext = {
          userInfo: newUserInfo,
          cart: appContext.cart,
          cartItems: appContext.cartItems,
        };

        props.setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));

        toast.success("User details updated successfully!");
      } catch (error) {
        if (error.response.status === 401) {
          newAppContext = {
            userInfo: null,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
          toast.error("Your session expired. Please try to login again");
        }
        toast.error(error);
      }
    } else {
      toast.error("Make sure you make a change before trying to submit!");
    }
  }

  return (
    <>
      <h2>Edit User Details</h2>
      <div className="account_inputs">
        <label>First Name</label>
        <input
          placeholder={appContext.userInfo.firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <label>Last Name</label>
        <input
          placeholder={appContext.userInfo.lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <label>Email</label>
        <input
          placeholder={appContext.userInfo.email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Phone Number</label>
        <input
          placeholder={appContext.userInfo.phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <div className="submit" onClick={editUser}>
        Submit
      </div>
    </>
  );
}

export default UserAccountDetails;