import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";
import SpecialRequest from "../../tools/SpecialRequest";
import Modal from "react-bootstrap/Modal";
import "./itemModal.scss";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import MenuContext from "../../MenuContext";
import ApplicationContext from "../../ApplicationContext";
import { formatMenuItemExtras } from "../../utils/menu/FormatMenuItemExtras";
import { FaWindowClose } from "react-icons/fa";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../constants";
import {
  updateCartAfterDeleteItem,
  updateCartItemsAfterDeleteItem,
} from "../../utils/cart/UpdateCartAfterDeleteItem";
import { toast } from "react-toastify";
import { editCartValues } from "../../utils/cart/EditCartValues";
import Loading from "../../tools/Loading";

function EditModal(props) {
  const menuContext = useContext(MenuContext);
  const appContext = useContext(ApplicationContext);
  const [runLayoutEffect, setRunLayoutEffect] = useState(true);
  const isFirstRender = useRef(true);

  // Use useState to initialize 'extras' with an empty array
  const [extras, setExtras] = useState([]);
  // Use useEffect to update 'extras' whenever 'props.selectedItem.Extras' changes
  useEffect(() => {
    const extrasArray = Object.values(props.selectedItem.Extras);
    setExtras(extrasArray);
  }, [props.selectedItem.Extras]);

  const [quantity, setQuantity] = useState(props.selectedItem.ItemQuantity);
  const [selectedSize, setSelectedSize] = useState();
  const [newExtras, setNewExtras] = useState(props.selectedItem.Extras);
  const [sameNameItems, setSameNameItems] = useState([]);
  const [menuItemExtras, setMenuItemExtras] = useState();
  const [itemFinalTotal, setItemFinalTotal] = useState(
    (Math.round(props.selectedItem.ItemTotalPrice * 100) / 100).toFixed(2)
  );
  var specialRequests = "";
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    var sameNameItemsArray = [];
    for (var i = 0; i < menuContext.items.length; i++) {
      if (menuContext.items[i].itemName === props.selectedItem.ItemName) {
        if (menuContext.items[i].itemSize === props.selectedItem.SizeCode) {
          menuContext.items[i].active = "Active";
        }
        sameNameItemsArray.push(menuContext.items[i]);
      }
    }
    for (var x = 0; x < sameNameItemsArray.length; x++) {
      if (sameNameItemsArray[x].itemSize !== props.selectedItem.SizeCode) {
        sameNameItemsArray[x].active = null;
      }
    }
    if (sameNameItemsArray.length > 1) {
      setSelectedSize(props.selectedItem);
    }
    const sortedArray = sameNameItemsArray.sort(
      (a, b) => a.itemPrice - b.itemPrice
    );
    setSameNameItems(sortedArray);
  }, [props]);

  useLayoutEffect(() => {
    if (!isFirstRender.current && runLayoutEffect) {
      for (var q = 0; q < props.selectedItem.Extras.length; q++) {
        var id = `${props.selectedItem.Extras[q].ExtraName.replace(/\s/g, "")}${
          props.selectedItem.Extras[q].ExtraDescription
        }`;
        var makeActive = document.getElementById(id);
        if (makeActive) {
          makeActive.classList.add("Active");
        }
      }
    } else {
      // This is the initial load, so set the flag to false
      isFirstRender.current = false;
    }
  });

  useEffect(() => {
    if (props.selectedItem.ItemOptions) {
      const itemExtraOptions = props.selectedItem.ItemOptions.split("||");

      var menuItemExtrasArray = [];

      for (var a = 0; a < itemExtraOptions.length; a++) {
        for (var i = 0; i < menuContext.extras.length; i++) {
          if (menuContext.extras[i].extraType === itemExtraOptions[a]) {
            menuItemExtrasArray.push(menuContext.extras[i]);
          }
        }
      }

      var formattedMenuItemExtras = formatMenuItemExtras(menuItemExtrasArray);
    }
    setMenuItemExtras(formattedMenuItemExtras);
  }, []);

  async function addQuantity() {
    var newQuantity = quantity + 1;
    setItemFinalTotal(
      (
        parseFloat(itemFinalTotal) + parseFloat(itemFinalTotal / quantity)
      ).toFixed(2)
    );
    setQuantity(newQuantity);
  }

  async function subtractQuantity() {
    if (quantity > 1) {
      var newQuantity = quantity - 1;
      setItemFinalTotal(
        (
          parseFloat(itemFinalTotal) - parseFloat(itemFinalTotal / quantity)
        ).toFixed(2)
      );
      setQuantity(newQuantity);
    }
  }

  async function selectToppings(topping) {
    //check if that topping already has an active topping
    var alreadyActiveSameTopping = document.querySelector(
      `.${topping.extraName.replace(/\s/g, "")}.Active`
    );
    var addShowingPriceToExtra = document.getElementById(
      `${topping.extraName.replace(/\s/g, "")}_selected`
    );

    //if it does remove Active
    if (alreadyActiveSameTopping) {
      alreadyActiveSameTopping.classList.remove("Active");
    }

    //set topping clicked on to be the active topping
    var setActiveExtra = document.getElementById(
      `${topping.extraName.replace(/\s/g, "")}${topping.extraDescription}`
    );
    if (setActiveExtra) {
      if (alreadyActiveSameTopping !== setActiveExtra) {
        setActiveExtra.classList.add("Active");
      }
      addShowingPriceToExtra.innerHTML = `${topping.extraName} <span class="extraToppingPrice fs-6 fw-bold">$${topping.extraPrice}</span>`;
      addShowingPriceToExtra.style.display = "inline-block";
    }

    var top = {
      Active: topping.active,
      CartExtra_CartID: topping.cartExtra_CartID,
      cartExtra_CartItemID: topping.cartExtra_CartItemID,
      ExtraCode: topping.extraCode,
      ExtraDescription: topping.extraDescription,
      ExtraName: topping.extraName,
      ExtraPrice: topping.extraPrice,
      ExtraSize: topping.extraSize,
      ExtraType: topping.extraType,
      Extras: topping.extras,
      CartExtra_MenuItemExtraID: topping.menuItemExtraID,
      PizzaHalfCode: topping.pizzaHalfCode,
    };
    if (newExtras.length < 1) {
      setNewExtras(newExtras.concat(top));
      setItemFinalTotal(
        (
          parseFloat(itemFinalTotal) +
          parseFloat(topping.extraPrice) * quantity
        ).toFixed(2)
      );
    } else {
      var sameExtra = false;
      for (var m = 0; m < newExtras.length; m++) {
        if (
          //(if) it is the same exact extra
          newExtras[m].ExtraName === topping.extraName &&
          newExtras[m].ExtraDescription === topping.extraDescription
        ) {
          setItemFinalTotal(
            (
              parseFloat(itemFinalTotal) -
              parseFloat(newExtras[m].ExtraPrice) * quantity
            ).toFixed(2)
          );
          newExtras.splice(m, 1);
          sameExtra = true;

          addShowingPriceToExtra.innerHTML = `${topping.extraName}`;
          addShowingPriceToExtra.style.display = "inline-block";

          break;
        } else if (
          //(else if) it is the same name pizza extra not exactly the same
          newExtras[m].ExtraName === topping.extraName &&
          newExtras[m].ExtraDescription !== topping.extraDescription
        ) {
          setItemFinalTotal(
            (
              parseFloat(topping.extraPrice) * quantity +
              (parseFloat(itemFinalTotal) -
                parseFloat(newExtras[m].ExtraPrice) * quantity)
            ).toFixed(2)
          );
          newExtras.splice(m, 1);

          break;
        } else {
          //else its not the same extra at all
          setItemFinalTotal(
            (
              parseFloat(topping.extraPrice) * quantity +
              parseFloat(itemFinalTotal)
            ).toFixed(2)
          );
        }
      }
      if (sameExtra !== true) {
        setNewExtras(newExtras.concat(top));
      }
    }
  }

  async function selectExtra(extra) {
    //check if that topping already has an active extra of same type
    var alreadyActiveSameExtraType = document.querySelector(
      `.${extra.extraType.replace(/\s/g, "")}.Active`
    );
    //if it does remove Active
    if (
      alreadyActiveSameExtraType &&
      extra.extraCode !== "Add Extra" &&
      extra.extraCode !== "Choose your Toppings"
    ) {
      alreadyActiveSameExtraType.classList.remove("Active");
    }

    //set topping clicked on to be the active topping
    var setActiveExtra = document.getElementById(
      `${extra.extraName.replace(/\s/g, "")}${extra.extraDescription}`
    );
    if (setActiveExtra) {
      if (alreadyActiveSameExtraType !== setActiveExtra) {
        setActiveExtra.classList.add("Active");
      }
    }

    var ex = {
      Active: extra.active,
      CartExtra_CartID: extra.cartExtra_CartID,
      cartExtra_CartItemID: extra.cartExtra_CartItemID,
      ExtraCode: extra.extraCode,
      ExtraDescription: extra.extraDescription,
      ExtraName: extra.extraName,
      ExtraPrice: extra.extraPrice,
      ExtraSize: extra.extraSize,
      ExtraType: extra.extraType,
      Extras: extra.extras,
      CartExtra_MenuItemExtraID: extra.menuItemExtraID,
      PizzaHalfCode: extra.pizzaHalfCode,
    };
    if (newExtras.length < 1) {
      setNewExtras(newExtras.concat(ex));
      setItemFinalTotal(
        parseFloat(itemFinalTotal) +
          parseFloat(extra.extraPrice).toFixed(2) * quantity
      );
    } else {
      var add = true;
      for (var m = 0; m < newExtras.length; m++) {
        if (
          newExtras[m].ExtraCode === extra.extraCode &&
          extra.extraCode !== "Add Extra" &&
          extra.extraCode !== "Choose your Toppings"
        ) {
          if (newExtras[m].ExtraName !== extra.extraName) {
            //if it is not the same extra
            setItemFinalTotal(
              (
                parseFloat(extra.extraPrice) * quantity +
                (parseFloat(itemFinalTotal) -
                  parseFloat(newExtras[m].ExtraPrice) * quantity)
              ).toFixed(2)
            );
            newExtras.splice(m, 1);
            add = true;
          } else {
            //else it is the same extra
            setItemFinalTotal(
              (
                parseFloat(itemFinalTotal) -
                parseFloat(newExtras[m].ExtraPrice) * quantity
              ).toFixed(2)
            );
            newExtras.splice(m, 1);
            add = false;
            return;
          }
        } else {
          if (newExtras[m].ExtraName !== extra.extraName) {
            setItemFinalTotal(
              (
                parseFloat(extra.extraPrice) * quantity +
                parseFloat(itemFinalTotal)
              ).toFixed(2)
            );
            add = true;
          } else {
            setItemFinalTotal(
              (
                parseFloat(itemFinalTotal) -
                parseFloat(extra.extraPrice) * quantity
              ).toFixed(2)
            );
            setActiveExtra.classList.remove("Active");
            newExtras.splice(m, 1);
            add = false;
            return;
          }
        }
      }
      if (add === true) {
        setNewExtras(newExtras.concat(ex));
      }
    }
  }

  const selectSize = (i) => {
    var newObject = {
      ItemPrice: i.itemPrice,
      ItemName: i.itemName,
      CartItem_MenuItemID: i.menuItemID,
      ItemCategory: i.itemCategory,
      ItemSubCategory: i.itemSubCategory,
      ItemDescription: i.itemDescription,
      ItemOptions: i.itemOptions,
      MenuID: i.menuID,
      SizeCode: i.itemSize,
    };
    setSelectedSize(newObject);
    setItemFinalTotal(
      parseFloat(Math.round(i.itemPrice * quantity * 100) / 100).toFixed(2)
    ); // Set the flag to false when you don't want to run useLayoutEffect
    setRunLayoutEffect(false);
    var optionsWithActiveClass = document.querySelectorAll(".option.Active");
    optionsWithActiveClass.forEach((element) => {
      element.classList.remove("Active");
    });

    setNewExtras([]);

    var alreadyActiveSize = document.querySelector(".size.Active");
    if (alreadyActiveSize) {
      alreadyActiveSize.classList.remove("Active");
    }
    var makeActiveSize = document.getElementById(`${i.itemSize}Size`);
    if (makeActiveSize) {
      makeActiveSize.classList.add("Active");
    }

    var menuItemExtrasArray = [];
    const itemExtraOptions = i.ItemOptions.split("||");
    for (var a = 0; a < itemExtraOptions.length; a++) {
      for (var x = 0; x < menuContext.extras.length; x++) {
        if (menuContext.extras[x].extraType === itemExtraOptions[a]) {
          menuItemExtrasArray.push(menuContext.extras[x]);
        }
      }
    }
    var formattedMenuItemExtras = formatMenuItemExtras(menuItemExtrasArray);

    setMenuItemExtras(formattedMenuItemExtras);

    // Get all elements with the class 'extraToppingPrice'
    const elements = document.getElementsByClassName("extraToppingPrice");

    // Convert the HTMLCollection to an array
    const elementsArray = Array.from(elements);

    // Loop through each element and set innerHTML to an empty string
    elementsArray.forEach((element) => {
      element.innerHTML = "";
    });
  };

  async function closeModal() {
    for (var i = 0; i < appContext.cartItems.length; i++) {
      if (
        appContext.cartItems[i].CartItemID === props.selectedItem.CartItemID
      ) {
        props.selectedItem.Extras = extras;
      }
    }
    props.setShowItemModal(false);
  }

  async function handleSubmit() {
    if (menuItemExtras) {
      var reqNum = 0;
      for (var a = 0; a < menuItemExtras.length; a++) {
        if (menuItemExtras[a].code.required === 1) {
          reqNum = reqNum + 1;
        }
      }

      if (reqNum > newExtras.length) {
        toast.error("Please make sure you make a selection for each option!");
        return;
      }
    }
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);

    specialRequests = document.getElementById("itemRequests").value;
    var itemGuid = CreateGuid();
    var selectedToppingsTotal = 0;
    for (var i = 0; i < newExtras.length; i++) {
      selectedToppingsTotal = selectedToppingsTotal + newExtras[i].ExtraPrice;
    }

    var userCartData = {
      CartID: appContext.userInfo.userID,
      OrderType: appContext.cart.OrderType,
      CartPrice: 0,
      Item: {
        CartItemID: itemGuid,
        ItemQuantity: quantity,
        CartItem_CartID: appContext.userInfo.userID,
        ItemSpecialRequests: specialRequests,
        Extras: [],
      },
    };

    if (selectedSize) {
      userCartData.Item.ItemPrice = selectedSize.ItemPrice;
      userCartData.Item.ItemName = selectedSize.ItemName;
      userCartData.Item.CartItem_MenuItemID = selectedSize.CartItem_MenuItemID;
      userCartData.Item.ItemCategory = selectedSize.ItemCategory;
      userCartData.Item.ItemSubCategory = selectedSize.ItemSubCategory;
      userCartData.Item.ItemDescription = selectedSize.ItemDescription;
      userCartData.Item.ItemOptions = selectedSize.ItemOptions;
      userCartData.Item.MenuID = selectedSize.MenuID;
      userCartData.Item.SizeCode = selectedSize.SizeCode;
      userCartData.Item.OrderType = selectedSize.orderType;
      userCartData.Item.OrderDay = selectedSize.orderDay;
      userCartData.Item.OrderTime = selectedSize.orderTime;
    } else {
      userCartData.Item.ItemPrice = props.selectedItem.ItemPrice;
      userCartData.Item.ItemName = props.selectedItem.ItemName;
      userCartData.Item.CartItem_MenuItemID =
        props.selectedItem.CartItem_MenuItemID;
      userCartData.Item.ItemCategory = props.selectedItem.ItemCategory;
      userCartData.Item.ItemSubCategory = props.selectedItem.ItemSubCategory;
      userCartData.Item.ItemDescription = props.selectedItem.ItemDescription;
      userCartData.Item.ItemOptions = props.selectedItem.ItemOptions;
      userCartData.Item.MenuID = props.selectedItem.MenuID;
      userCartData.Item.SizeCode = null;
      userCartData.Item.OrderType = props.selectedItem.OrderType;
      userCartData.Item.OrderDay = props.selectedItem.OrderDay;
      userCartData.Item.OrderTime = props.selectedItem.OrderTime;

      if (props.selectedItem.SizeCode) {
        userCartData.Item.SizeCode = props.selectedItem.SizeCode;
      }
    }
    userCartData.Item.RewardCost = 0;

    var extrasTotal = 0.0;
    var formattedExtras = [];
    for (var p = 0; p < newExtras.length; p++) {
      extrasTotal =
        parseFloat(extrasTotal) + parseFloat(newExtras[p].ExtraPrice);

      // selectedExtras[p].Extras = null;
      newExtras[p].CartExtra_CartID = appContext.userInfo.userID;
      newExtras[p].CartExtra_CartItemID = itemGuid;
      if (newExtras[p].ExtraDescription === "FirstHalf") {
        newExtras[p].PizzaHalfCode = "(1st Half)";
      } else if (newExtras[p].ExtraDescription === "SecondHalf") {
        newExtras[p].PizzaHalfCode = "(2nd Half)";
      } else {
        newExtras[p].PizzaHalfCode = null;
      }
      formattedExtras[p] = {
        Active: newExtras[p].Active,
        CartExtra_CartID: newExtras[p].CartExtra_CartID,
        CartExtra_CartItemID: newExtras[p].CartExtra_CartItemID,
        ExtraCode: newExtras[p].ExtraCode,
        ExtraDescription: newExtras[p].ExtraDescription,
        ExtraName: newExtras[p].ExtraName,
        ExtraPrice: newExtras[p].ExtraPrice,
        ExtraSize: newExtras[p].ExtraSize,
        ExtraType: newExtras[p].ExtraType,
        Extras: newExtras[p].Extras,
        CartExtra_MenuItemExtraID: newExtras[p].CartExtra_MenuItemExtraID,
        PizzaHalfCode: newExtras[p].PizzaHalfCode,
      };
      userCartData.Item.Extras.push(formattedExtras[p]);
    }
    var totalPrice = 0.0;
    if (selectedSize) {
      totalPrice = (selectedSize.ItemPrice + extrasTotal) * quantity;
    } else {
      totalPrice = (props.selectedItem.ItemPrice + extrasTotal) * quantity;
    }
    let numDouble = totalPrice.toFixed(2);
    userCartData.Item.ItemTotalPrice = parseFloat(numDouble);

    var newAppContext;
    var newItem = userCartData.Item;

    var newPrice = 0;
    for (var a = 0; a < appContext.cartItems.length; a++) {
      if (appContext.cartItems[a].CartItemID !== props.selectedItem.CartItemID) {
        newPrice = newPrice + appContext.cartItems[a].ItemTotalPrice;
      }
    }

    try {
      await axios.delete(
        `${BASE_API}api/Cart/DeleteCartItem?cartitemId=` +
          props.selectedItem.CartItemID +
          "&deleteTotal=" +
          newPrice,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );

      var cartItemToDelete = props.selectedItem;
      var updatedCart = updateCartAfterDeleteItem(appContext, newPrice);
      var updatedCartItems = updateCartItemsAfterDeleteItem(
        appContext,
        cartItemToDelete
      );
      newAppContext = {
        userInfo: appContext.userInfo,
        cart: updatedCart,
        cartItems: updatedCartItems,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } catch (error) {
      if (error.response.status === 401) {
        newAppContext = {
          userInfo: null,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      }
      toast.error("Your session expired. Please try to login again");
    }

    var menuItemID;
    if (selectedSize) {
      menuItemID = selectedSize.CartItem_MenuItemID;
    } else {
      menuItemID = props.selectedItem.CartItem_MenuItemID;
    }
    try {
      await axios.post(
        `${BASE_API}api/Cart/AddToCart/${menuItemID}`,
        userCartData,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );
      //update cart with newItem and place in updatedCart
      const updatedCartObject = editCartValues(newAppContext, newItem);
      //check if itemExists is true and replace item with Same === true
      var cartItems = newAppContext.cartItems
        ? [...newAppContext.cartItems, newItem]
        : [newItem];

      newAppContext = {
        userInfo: appContext.userInfo,
        cart: updatedCartObject,
        cartItems: cartItems,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } catch (error) {
      if (error.response.status === 401) {
        newAppContext = {
          userInfo: null,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      }
      toast.error(error);
    }
    props.setShowItemModal(false);
    props.setCartOpen(false);
  }

  return (
    <>
      <Modal className="itemModal" show={props.showItemModal}>
        <Modal.Body>
          <Modal.Title>
            <h2 className="fs-3 m-0 fw-bold">Edit Your Item!</h2>
            <FaWindowClose
              onClick={closeModal}
              style={{ width: "1.5em", height: "1.5em" }}
              className="text-danger"
            />
          </Modal.Title>

          <div className="itemModal_body">
            <h2 className="itemModal_name fw-bold w-100 mb-2">
              {props.selectedItem.ItemName}
            </h2>

            {props.selectedItem.ItemDescription && (
              <div className="w-100 mb-1 fs-6">
                <em>{props.selectedItem.ItemDescription}</em>
              </div>
            )}

            {props.selectedItem.CartItem_MenuItemID !== -1 && (
              <div className="itemModal_quantity p-1 d-flex align-items-center justify-content-between w-100 mb-1 bg-light border border-dark rounded">
                <h4 className="fs-4 m-0">Quantity:</h4>
                <div className="d-flex align-items-center">
                  <div className="sub" onClick={subtractQuantity}>
                    <AiFillMinusCircle
                      style={{ width: "2em", height: "2em" }}
                    />
                  </div>
                  <div className="fs-1 mx-3 fw-bold text-danger">
                    {quantity}
                  </div>
                  <div className="add" onClick={addQuantity}>
                    <AiFillPlusCircle style={{ width: "2em", height: "2em" }} />
                  </div>
                </div>
              </div>
            )}

            {sameNameItems.length > 1 && (
              <div className="itemModal_options w-100 mt-2">
                <h5>Select a size:</h5>
                <div className="itemModal_sizes d-flex flex-column w-100">
                  {sameNameItems?.map((i) => (
                    <div
                      id={`${i.itemSize}Size`}
                      className={`size d-flex justify-content-between w-100 mb-1 bg-light border border-dark rounded options ${i.active}`}
                      key={i.menuItemID}
                      onClick={() => selectSize(i)}
                      style={{ padding: "0.35rem 0.5rem" }}
                    >
                      <div className="fs-6">{i.itemSize}</div>
                      <div className="fs-6 fw-bold">
                        {"$" + i.itemPrice.toFixed(2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {menuItemExtras?.map((i, index) => (
              <div key={index} className="itemModal_options w-100 mt-2">
                <div className="extra_code_container">
                  <h5 className="">{i.code.extraCode + ":"}</h5>
                  {i.extras[0].options[0].required > 0 && (
                    <h5 className="required_tab">
                      {i.code.required > 1 ? (
                        <>
                          Max:
                          <span className="mx-1">
                            <b>({i.code.required})</b>
                          </span>
                        </>
                      ) : (
                        <>Required</>
                      )}
                    </h5>
                  )}
                </div>

                {i.extras?.map((j, index2) => (
                  <div
                    key={index2}
                    className="extraOptions d-flex justify-content-between w-100 mb-1 bg-light rounded"
                  >
                    {j.options.length > 1 ? (
                      <>
                        <div className="option_choice_pizza d-flex w-100 justify-content-between">
                          <div
                            className="fs-6 px-2 py-2"
                            id={`${j.options[0].extraName.replace(
                              /\s/g,
                              ""
                            )}_selected`}
                          >
                            {j.name}
                          </div>
                          {j.options.length > 1 && (
                            <div className="options d-flex px-1 py-1">
                              {j.options?.map((k, index3) => (
                                <div
                                  key={index3}
                                  id={`${k.extraName.replace(/\s/g, "")}${
                                    k.extraDescription
                                  }`}
                                  className={`mx-1 ${
                                    k.extraDescription
                                  } ${k.extraName.replace(/\s/g, "")}`}
                                  onClick={() => selectToppings(k)}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <div
                        id={`${j.options[0].extraName.replace(/\s/g, "")}${
                          j.options[0].extraDescription
                        }`}
                        className={`option_choice d-flex justify-content-between w-100 h-100 fs-6 px-2 ${j.options[0].extraType.replace(
                          /\s/g,
                          ""
                        )}`}
                        onClick={() => selectExtra(j.options[0])}
                      >
                        <div>{j.name}</div>
                        {j.options[0].extraPrice > 0 ? (
                          <div className="fw-bold">
                            ${j.options[0].extraPrice.toFixed(2)}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}

            <SpecialRequest />
          </div>

          <div
            className="itemModal_Submit"
            onClick={!isDisabled ? () => handleSubmit() : undefined}
          >
            {isDisabled ? (
              <>
                <div className="itemModal_totalPrice">
                  Total Price: ${itemFinalTotal}
                </div>
                <button>
                  <Loading />
                </button>
              </>
            ) : (
              <>
                <div className="itemModal_totalPrice">
                  Total Price: ${itemFinalTotal}
                </div>
                <button>EDIT ITEM</button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditModal;
