export const formatOrdersData = (data) => {
  var orders = [];
  if (!data.orders) {
    return null;
  }
  for (var i = 0; i < data.orders.length; i++) {
    data.orders[i].orderedDate = new Date(data.orders[i].orderedDate.replace("T", " ")).toLocaleString();
    data.orders[i].requestedDate = new Date(data.orders[i].requestedDate.replace("T", " ")).toLocaleString();
    orders.push(data.orders[i]);
    orders[i].items = [];
    var itemsLength = 0;

    for (var j = 0; j < data.orderItems.length; j++) {
      if (data.orders[i].orderID === data.orderItems[j].orderItem_OrderID) {
        orders[i].items.push(data.orderItems[j]);
        orders[i].items[itemsLength].extras = [];

        for (var x = 0; x < data.orderItemExtras.length; x++) {
          if (
            data.orderItems[j].orderItemID ===
            data.orderItemExtras[x].orderExtra_OrderItemID
          ) {
            orders[i].items[itemsLength].extras.push(data.orderItemExtras[x]);
          }
        }
        itemsLength = itemsLength + 1;
      }
    }
  }

  return orders;
};
