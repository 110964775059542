import React, { useEffect, useState } from "react";

function FilteredMenuItems(props) {
  const [items, setItems] = useState({});

  useEffect(() => {
    // Create an object to store items by itemSubCategory
    const itemsBySubCategory = {};

    for (const item of props.filteredMenuItems) {
      var itemCategory;
      if(!item.itemSubCategory){
        itemCategory = item.itemCategory;
      } else {
        itemCategory = item.itemSubCategory;
      }

      if (itemCategory) {
        if (!itemsBySubCategory[itemCategory]) {
          // Initialize an array for the itemSubCategory if it doesn't exist
          itemsBySubCategory[itemCategory] = [];
        }

        // Check if an item with the same itemName already exists in the array
        const duplicateItemIndex = itemsBySubCategory[
          itemCategory
        ].findIndex((existingItem) => existingItem.itemName === item.itemName);

        if (duplicateItemIndex === -1) {
          // If no duplicate item found, push the item to the array
          itemsBySubCategory[itemCategory].push(item);
        }
      }
    }

    // itemsBySubCategory now contains items grouped by itemSubCategory
    setItems(itemsBySubCategory);
  }, [props.filteredMenuItems]);

  async function toggleItemModal(item) {
    item.active = "Active";
    props.setSelectedItem(item);
    props.setShowItemModal(true);
  }

  return (
    <>
      {props.filteredMenuItems.length > 0 ? (
        <div>
          {Object.keys(items).map((category) => (
            <div key={category}>
              <h2 className="mx-3 fw-bold fs-2 mt-4">{category}</h2>
              {items[category].map((item) => (
                <div
                  className="card m-2"
                  key={item.menuItemID}
                  onClick={() => toggleItemModal(item)}
                >
                  <div className="px-3 py-2">
                    <h6 className="fw-bold itemName">{item.itemName}</h6>
                    <p className="mb-1 itemDescription"><em>{item.itemDescription}</em></p>
                    <p className="mb-1 itemPrice">${item.itemPrice.toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div>Sorry no items were found</div>
      )}
    </>
  );
}

export default FilteredMenuItems;
