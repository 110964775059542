import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../constants";

function EditRewardItemModal(props) {
  const [selectedTier, setSelectedTier] = useState();
  const [newPrice, setNewPrice] = useState();

  // useEffect(() => {
  //     if (selectedTier) {
  //         for (var w = 0; w < props.allRewards.length; w++) {
  //             if (props.allRewards[w].points !== props.currentTier.points) {
  //                 setSelectedTier(props.allRewards[w].points);
  //                 return;
  //             }
  //         }
  //     }
  // }, []);

  async function closeRewardItemModal() {
    props.setShowEditRewardItemModal(false);
  }

  const handleSelectChange = (event) => {
    setSelectedTier(event.target.value);
  };

  async function updateRewardItem() {
    var tierToMoveItemInto;
    for (var m = 0; m < props.allRewards.length; m++) {
      if (!selectedTier) {
        tierToMoveItemInto = props.currentTier.storeRewardID;
      } else {
        if (selectedTier.toString() === props.allRewards[m].points) {
          tierToMoveItemInto = props.allRewards[m].storeRewardID;
        }
      }
    }

    var reward = {};
    if (!newPrice) {
      reward = {
        RewardItem_StoreRewardID: tierToMoveItemInto,
        RewardItemPrice: props.itemToEdit.rewardItemPrice,
        StoreRewardItemID: props.itemToEdit.storeRewardItemID,
      };
    } else {
      reward = {
        RewardItem_StoreRewardID: tierToMoveItemInto,
        RewardItemPrice: newPrice,
        StoreRewardItemID: props.itemToEdit.storeRewardItemID,
      };
    }

    try {
      await axios.put(`${BASE_API}UpdateRewardItem`, reward, {
        headers: {
          Application: APPLICATION,
        },
      });

      props.getRewards();
      props.setShowEditRewardItemModal(false);
      props.setShowRewardItemModal(false);
    } catch (error) {
      toast.error("Update Reward Item failed");
    }
  }

  return (
    <>
      <Modal
        className="editRewardItemModal"
        show={props.showEditRewardItemModal}
      >
        <Modal.Body>
          <Modal.Header className="d-flex justify-content-end">
            <FaWindowClose
              onClick={closeRewardItemModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          {props.itemToEdit.rewardItem_MenuItemID === -1 ? (
            <Modal.Title>
              Edit ${props.itemToEdit.rewardItemPrice} Discount
            </Modal.Title>
          ) : (
            <Modal.Title>Edit {props.itemToEdit.itemName}</Modal.Title>
          )}

          <div className="edit_reward_item_container">
            <div className="input_fields dropdown">
              <div className="label">Move Tier:</div>
              <select onChange={handleSelectChange}>
                <option>Select a Tier</option>
                {props.otherRewardTiers.map((tier, index) => (
                  <option key={index} value={tier.points}>
                    {tier.points} Points
                  </option>
                ))}
              </select>
            </div>
            <div className="input_fields">
              <label className="label">New Price:</label>
              <input
                type="text"
                placeholder={`Currently - $${props.itemToEdit.rewardItemPrice}`}
                onChange={(e) => setNewPrice(e.target.value)}
              />
            </div>

            <div className="submit" onClick={updateRewardItem}>
              Submit
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditRewardItemModal;
