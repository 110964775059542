import React, { useContext, useState, useEffect } from "react";
import ApplicationContext from "../../../ApplicationContext";
import StoreContext from "../../../StoreContext";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../../constants";
import { toast } from "react-toastify";
import { convertToMilitaryTime } from "../../../utils/store/ConvertToMiltaryTime";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function StoreHours() {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [key, setKey] = useState("PickupHours");

  const [day, setDay] = useState();
  const [deliveryDay, setDeliveryDay] = useState();
  const [newStart, setNewStart] = useState();
  const [newEnd, setNewEnd] = useState();
  const [newDeliveryStart, setNewDeliveryStart] = useState();
  const [newDeliveryEnd, setNewDeliveryEnd] = useState();

  //PICKUP HOURS
  async function changeStoreHours() {
    try {
      if (!newStart || !newEnd) {
        toast.error(
          "Please make sure you fill out both opening and closing times for this day."
        );
        return;
      }
      var start = convertToMilitaryTime(newStart);
      var end = convertToMilitaryTime(newEnd);
      if (!start || !end) {
        toast.error("Please make sure your times are in the correct format.");
        return;
      }
      var storeHours = {
        DayOfWeek: day,
        StartTime: start,
        EndTime: end,
      };
      await axios.put(`${BASE_API}api/Store/UpdateStoreHours`, storeHours, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          Application: APPLICATION,
        },
      });
      toast.success(`Updated ${day}'s Hours Successfully`);
    } catch (err) {
      toast.error("Failed to update store hours.");
    }
  }

  async function changeThisDay(h) {
    var allButtons = document.querySelectorAll(".button");
    const specificId = `${h.dayOfWeek}_hours_button`;
    for (const element of allButtons) {
      if (element.id === specificId) {
        element.disabled = false;
      } else {
        if (element.disabled === true) {
          element.disabled = false;
        } else {
          element.disabled = true;
        }
      }
    }

    setDay(h.dayOfWeek);
    const open = `${h.dayOfWeek}_open`;
    const close = `${h.dayOfWeek}_close`;
    let openElement = document.getElementById(open);
    let closeElement = document.getElementById(close);
    if (openElement) {
      if (openElement.disabled === true) {
        openElement.disabled = false;
      } else {
        setNewStart();
        setNewEnd();
        openElement.value = "";
        openElement.disabled = true;
      }
    }
    if (closeElement) {
      if (closeElement.disabled === true) {
        closeElement.disabled = false;
      } else {
        setNewStart();
        setNewEnd();
        closeElement.value = "";
        closeElement.disabled = true;
      }
    }
  }

  //DELIVERY HOURS
  async function changeDeliveryStoreHours() {
    try {
      if (!newDeliveryStart || !newDeliveryEnd) {
        toast.error(
          "Please make sure you fill out both opening and closing times for this day."
        );
        return;
      }
      var start = convertToMilitaryTime(newDeliveryStart);
      var end = convertToMilitaryTime(newDeliveryEnd);
      if (!start || !end) {
        toast.error("Please make sure your times are in the correct format.");
        return;
      }
      var storeHours = {
        DayOfWeek: deliveryDay,
        StartTime: start,
        EndTime: end,
      };
      await axios.put(
        `${BASE_API}api/Store/UpdateDeliveryStoreHours`,
        storeHours,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );
      toast.success(`Updated ${deliveryDay}'s Delivery Hours Successfully`);
    } catch (err) {
      toast.error("Failed to update store hours.");
    }
  }

  async function changeThisDeliveryDay(h) {
    var allButtons = document.querySelectorAll(".deliveryButton");
    const specificId = `delivery_${h.dayOfWeek}_hours_button`;
    for (const element of allButtons) {
      if (element.id === specificId) {
        element.disabled = false;
      } else {
        if (element.disabled === true) {
          element.disabled = false;
        } else {
          element.disabled = true;
        }
      }
    }

    // var allSubmitButtons = document.querySelectorAll(".deliverySubmitButton");
    // const specificSubmitId = `delivery_${h.dayOfWeek}_submit_button`;
    // for (const element of allSubmitButtons) {
    //   if (element.id === specificSubmitId) {
    //     if (element.disabled === true) {
    //       element.disabled = false;
    //     } else {
    //       element.disabled = true;
    //     }
    //   } else {
    //     if (element.disabled === true) {
    //       element.disabled = true;
    //     } else {
    //       element.disabled = false;
    //     }
    //   }
    // }

    setDeliveryDay(h.dayOfWeek);
    const open = `delivery_${h.dayOfWeek}_open`;
    const close = `delivery_${h.dayOfWeek}_close`;
    let openElement = document.getElementById(open);
    let closeElement = document.getElementById(close);
    if (openElement) {
      if (openElement.disabled === true) {
        openElement.disabled = false;
      } else {
        setNewDeliveryStart();
        setNewDeliveryEnd();
        openElement.value = "";
        openElement.disabled = true;
      }
    }
    if (closeElement) {
      if (closeElement.disabled === true) {
        closeElement.disabled = false;
      } else {
        setNewDeliveryStart();
        setNewDeliveryEnd();
        closeElement.value = "";
        closeElement.disabled = true;
      }
    }
  }

  //GENERATE AND HANDLE TIME
  const generateTimeOptions = () => {
    const options = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const isPM = hours >= 12;
        const formattedHours = (((hours + 11) % 12) + 1)
          .toString()
          .padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const time = `${formattedHours}:${formattedMinutes} ${isPM ? "PM" : "AM"
          }`;
        options.push({ label: time, value: time });
      }
    }
    return options;
  };
  const timeOptions = generateTimeOptions();

  const handleTimeChange = (e) => {
    setNewStart(e.target.value);
  };
  const handleClosingTimeChange = (e) => {
    setNewEnd(e.target.value);
  };

  const handleDeliveryTimeChange = (e) => {
    setNewDeliveryStart(e.target.value);
  };
  const handleDeliveryClosingTimeChange = (e) => {
    setNewDeliveryEnd(e.target.value);
  };


  async function setTheKey(k) {
    setKey(k);

    var alreadyActiveElement = document.querySelector(
      `.StoreHoursTabs .nav-item .nav-link.active`
    );
    if (alreadyActiveElement) {
      alreadyActiveElement.style.backgroundColor = "hsla(0, 0%, 84%, 0.85)";
      alreadyActiveElement.style.color = `black`;
      alreadyActiveElement.style.border = `1px solid black`;
    }

    const alphaValue = 0.5;
    // Parse the original color string to extract the RGB values
    const match = storeContext.color.match(/\d+/g);
    let modifiedLightColor;
    if (match && match.length === 3) {
      const [red, green, blue] = match;

      // Create the new color string with the alpha value
      modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
    }

    var activeThisElement = document.getElementById(
      `controlled-tab-example-tab-${k}`
    );
    if (activeThisElement) {
      activeThisElement.style.backgroundColor = modifiedLightColor;
      activeThisElement.style.color = `white`;
      activeThisElement.style.border = `2px solid ${storeContext.color}`;
      activeThisElement.style.borderBottom = `2px solid ${storeContext.color}`;
    }
  }

  useEffect(() => {
    var activeThisElement = document.getElementById(
      `controlled-tab-example-tab-${key}`
    );
    const alphaValue = 0.5;
    // Parse the original color string to extract the RGB values
    const match = storeContext.color.match(/\d+/g);
    let modifiedLightColor;
    if (match && match.length === 3) {
      const [red, green, blue] = match;

      // Create the new color string with the alpha value
      modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
    }
    if (activeThisElement) {
      activeThisElement.style.backgroundColor = modifiedLightColor;
      activeThisElement.style.color = `white`;
      activeThisElement.style.border = `2px solid ${storeContext.color}`;
      activeThisElement.style.borderBottom = `2px solid ${storeContext.color}`;
    }
  }, []);

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        className="mb-3 StoreHoursTabs"
        activeKey={key}
        onSelect={(k) => setTheKey(k)}
      >
        <Tab eventKey="PickupHours" title="Pickup Hours">
          <div className="store_hours">
            <div className="card-body">
              <div className="hours">
                {storeContext.hours?.map((h, index) => (
                  <div key={index} className="day d-flex">
                    <div className="dayofWeek">{h.dayOfWeek}:</div>
                    <select
                      onChange={handleTimeChange}
                      className="open"
                      id={`${h.dayOfWeek}_open`}
                      disabled
                    >
                      <option value="">
                        {h.openTime.replace(
                          /(\d{2}):(\d{2}):(\d{2})/,
                          (match, hour, minute) => {
                            const parsedHour = parseInt(hour, 10);
                            const period = parsedHour >= 12 ? "PM" : "AM";
                            const formattedHour = (parsedHour % 12)
                              .toString()
                              .padStart(2, "0");
                            return `${formattedHour}:${minute} ${period}`;
                          }
                        )}{" "}
                        <div>(Current)</div>
                      </option>
                      {timeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="mx-1">to</div>
                    <select
                      onChange={handleClosingTimeChange}
                      className="close"
                      id={`${h.dayOfWeek}_close`}
                      disabled
                    >
                      <option value="">
                        {h.closeTime.replace(
                          /(\d{2}):(\d{2}):(\d{2})/,
                          (match, hour, minute) => {
                            const parsedHour = parseInt(hour, 10);
                            const period = parsedHour >= 12 ? "PM" : "AM";
                            const formattedHour = (parsedHour % 12)
                              .toString()
                              .padStart(2, "0");
                            return `${formattedHour}:${minute} ${period}`;
                          }
                        )}{" "}
                        <div>(Current)</div>
                      </option>
                      {timeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="changeButton">
                      <button
                        className="button"
                        id={`${h.dayOfWeek}_hours_button`}
                        onClick={() => changeThisDay(h)}
                      >
                        Change
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="submit">
                <button onClick={changeStoreHours}>Submit</button>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="DeliveryHours" title="Delivery Hours">
          <div className="delivery_store_hours">
            <div className="card-body">
              <div className="hours">
                {storeContext.deliveryHours?.map((h, index) => (
                  <div key={index} className="day d-flex">
                    <div className="dayofWeek">{h.dayOfWeek}:</div>
                    <select
                      onChange={handleDeliveryTimeChange}
                      className="open"
                      id={`delivery_${h.dayOfWeek}_open`}
                      disabled
                    >
                      <option value="">
                        {h.openTime.replace(
                          /(\d{2}):(\d{2}):(\d{2})/,
                          (match, hour, minute) => {
                            const parsedHour = parseInt(hour, 10);
                            const period = parsedHour >= 12 ? "PM" : "AM";
                            const formattedHour = (parsedHour % 12)
                              .toString()
                              .padStart(2, "0");
                            return `${formattedHour}:${minute} ${period}`;
                          }
                        )}{" "}
                        <div>(Current)</div>
                      </option>
                      {timeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="mx-1">to</div>
                    <select
                      onChange={handleDeliveryClosingTimeChange}
                      className="close"
                      id={`delivery_${h.dayOfWeek}_close`}
                      disabled
                    >
                      <option value="">
                        {h.closeTime.replace(
                          /(\d{2}):(\d{2}):(\d{2})/,
                          (match, hour, minute) => {
                            const parsedHour = parseInt(hour, 10);
                            const period = parsedHour >= 12 ? "PM" : "AM";
                            const formattedHour = (parsedHour % 12)
                              .toString()
                              .padStart(2, "0");
                            return `${formattedHour}:${minute} ${period}`;
                          }
                        )}{" "}
                        <div>(Current)</div>
                      </option>
                      {timeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="changeButton">
                      <button
                        className="deliveryButton"
                        id={`delivery_${h.dayOfWeek}_hours_button`}
                        onClick={() => changeThisDeliveryDay(h)}
                      >
                        Change
                      </button>
                      {/* <button onClick={changeDeliveryStoreHours}
                        className="deliverySubmitButton"
                        id={`delivery_${h.dayOfWeek}_submit_button`} disabled>Submit</button> */}
                    </div>
                  </div>
                ))}
              </div>
              <div className="submit">
                <button onClick={changeDeliveryStoreHours}>Submit</button>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </>
  );
}

export default StoreHours;
