export const handleGuestPhoneNumberChange = (e) => {
  const inputGuestPhoneNumber = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  let formattedGuestPhoneNumber = "";

  if (inputGuestPhoneNumber.length > 0) {
    formattedGuestPhoneNumber += inputGuestPhoneNumber.slice(0, 3);

    if (inputGuestPhoneNumber.length >= 4) {
      formattedGuestPhoneNumber += "-" + inputGuestPhoneNumber.slice(3, 6);

      if (inputGuestPhoneNumber.length >= 7) {
        formattedGuestPhoneNumber += "-" + inputGuestPhoneNumber.slice(6, 10);
      }
    }
  }

  return formattedGuestPhoneNumber;
};
