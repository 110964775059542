import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import "./navbar.scss";

function NavbarHome() {
    const [showDropdown, setShowDropdown] = useState(false);

    async function dropdown() {
        setShowDropdown(!showDropdown);
    }

    return (
        <>
            <header className="navbar_home">
                <div className="topbar" id="topbar">
                    <div className="left">
                        <a href="/"><img className="mylogo" src="/pictures/myorderportal.png" /></a>
                    </div>
                    <div className="right">
                        <div className="mobile_nav">
                            <HiMenu onClick={dropdown} />
                            <div className="dropdown" id="dropdown">
                                {showDropdown ? (
                                    <ul className="container-fluid list-unstyled mb-2">
                                        <li className="nav_item py-3 hover">
                                            <a target="_blank" href={`https://www.getmyorderportal.com/`}>
                                                Become a Partner
                                            </a>
                                        </li>
                                    </ul>
                                ) : null}
                            </div>
                        </div>
                        <div className="desktop_nav">
                            <ul className="container-fluid list-unstyled mb-2">
                                <li className="nav_item py-3 hover">
                                    <a target="_blank" href={`https://www.getmyorderportal.com/`}>
                                        Become a Partner
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default NavbarHome;