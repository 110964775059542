export const handlePhoneNumberChange = (e) => {
  const inputPhoneNumber = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  let formattedPhoneNumber = "";

  if (inputPhoneNumber.length > 0) {
    formattedPhoneNumber += inputPhoneNumber.slice(0, 3);

    if (inputPhoneNumber.length >= 4) {
      formattedPhoneNumber += "-" + inputPhoneNumber.slice(3, 6);

      if (inputPhoneNumber.length >= 7) {
        formattedPhoneNumber += "-" + inputPhoneNumber.slice(6, 10);
      }
    }
  }

  return formattedPhoneNumber;
};
