import React, { useState, useContext, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import axios from "axios";
import UserOrders from "../../components/account/UserOrders";
import UserAccountDetails from "../../components/account/UserAccountDetails";
import { APPLICATION, BASE_API } from "../../constants";
import ApplicationContext from "../../ApplicationContext";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./account.scss";
import { formatOrdersData } from "../../utils/orders/FormatUserOrders";

function Account(props) {
  const appContext = useContext(ApplicationContext);

  const [key, setKey] = useState("AccountOrders");
  const [userOrders, setUserOrders] = useState();

  const fetchUserOrders = async () => {
    try {
      const response = await axios.get(
        `${BASE_API}api/User/GetUserOrderHistory/${appContext.userInfo.userID}`,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );
      var orders = formatOrdersData(response.data);
      setUserOrders(orders);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const getKey = async () => {
      if (key === "AccountOrders") {
        fetchUserOrders();
      }
      if (key === "AccountDetails") {
        setUserOrders();
      }
    };
    if (APPLICATION) {
      getKey();
    }
  }, [key]);

  return (
    <>
      <Navbar
        setAppContext={props.setAppContext}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
      />
      <div className="account_page">
        <div className="account_container">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 AccountTabs"
          >
            <Tab eventKey="AccountOrders" title="Order History">
              <UserOrders userOrders={userOrders} />
            </Tab>
            <Tab eventKey="AccountDetails" title="Account Details">
              <UserAccountDetails setAppContext={props.setAppContext} />
            </Tab>
          </Tabs>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Account;
