import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import { IoIosUnlock, IoIosMail } from "react-icons/io";
import { CiBarcode } from "react-icons/ci";
import "./authenticateModal.scss";
import { APPLICATION, BASE_API } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";

function ResetPasswordModal(props) {
  const [resetCode, setResetCode] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [enteredEmail, setEnteredEmail] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");

  async function closeResetPasswordModal() {
    props.setShowResetPasswordModal(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    resetPassword();
    try {
      toast.success("Successful");
    } catch (err) {
      toast.error("Error");
    }
  };

  async function resetPassword() {
    if (customerEmail) {
      const customerResponse = await axios.get(
        `${BASE_API}api/User/ChceckIfCustomerEmailExists/${customerEmail}`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      if (customerResponse.data.length > 0) {
        try {
          const response = await axios.put(
            `${BASE_API}api/User/SendForgotPasswordEmail/${customerEmail}`,
            {
              email: customerEmail,
            },
            {
              headers: {
                Application: APPLICATION,
              },
            }
          );
          console.log("Password reset email sent:", response.data);
          toast.success("Reset password code sent to email.");
        } catch (error) {
          console.log("Error sending password reset email:", error);
          toast.error("Error. Please make sure you entered a valid email.");
        }
      }

      setEnteredEmail(true);
    }
  }

  return (
    <>
      <Modal className="authenticateModal" show={props.showResetPasswordModal}>
        <Modal.Body>
          <Modal.Header className="d-flex justify-content-end">
            <FaWindowClose
              onClick={closeResetPasswordModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          <Modal.Title>Reset Your Password</Modal.Title>
          {!enteredEmail ? (
            <>
              <div className="login_page loginregisterModal">
                <form>
                  <div className="container">
                    <div className="input_fields">
                      <label>
                        <IoIosMail style={{ width: "2em", height: "2em" }} />
                      </label>
                      <input
                        type="text"
                        placeholder="Email"
                        onChange={(e) => setCustomerEmail(e.target.value)}
                        value={customerEmail}
                        required
                      />
                    </div>

                    <div className="submit" onClick={resetPassword}>
                      Submit
                    </div>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="login_page loginregisterModal">
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="input_fields first">
                      <label>
                        <CiBarcode style={{ width: "2em", height: "2em" }} />
                      </label>
                      <input
                        type="text"
                        placeholder="Reset Code"
                        onChange={(e) => setResetCode(e.target.value)}
                        value={email}
                        required
                      />
                    </div>
                    <div className="input_fields">
                      <label>
                        <IoIosMail style={{ width: "2em", height: "2em" }} />
                      </label>
                      <input
                        type="text"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </div>
                    <div className="input_fields">
                      <label>
                        <IoIosUnlock style={{ width: "2em", height: "2em" }} />
                      </label>
                      <input
                        type="text"
                        placeholder="New Password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={email}
                        required
                      />
                    </div>
                    <div className="input_fields">
                      <label>
                        <IoIosUnlock style={{ width: "2em", height: "2em" }} />
                      </label>
                      <input
                        type="text"
                        placeholder="Confirm New Password"
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        value={email}
                        required
                      />
                    </div>

                    <div className="submit" onClick={resetPassword}>
                      Submit
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResetPasswordModal;
