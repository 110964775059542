import React, { useContext, useState } from "react";
import ApplicationContext from "../../../ApplicationContext";
import StoreContext from "../../../StoreContext";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_API, APPLICATION } from "../../../constants";

function StoreStatus(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);
  const [isPageLocked, setIsPageLocked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [isOrdersActive, setIsOrdersActive] = useState(false);
  const [isDeliveryActive, setIsDeliveryActive] = useState(false);

  async function toggleAcceptOrdersButton() {
    var editData = {
      isActive: "",
    };
    if (storeContext.isActive !== "true") {
      editData.isActive = "true";
    }
    await axios.put(`${BASE_API}api/Store/UpdateStoreOrderStatus`, editData, {
      headers: {
        Authorization: `Bearer ${appContext.userInfo.token}`,
        Application: APPLICATION,
      },
    });
    var newStoreContext = storeContext;
    if (storeContext.isActive !== "true") {
      newStoreContext.isActive = "true";
    } else {
      newStoreContext.isActive = "";
    }
    props.setStoreContext(newStoreContext);
    localStorage.setItem("storeContext", JSON.stringify(newStoreContext));

    setIsOrdersActive(!isOrdersActive);
  }
  async function toggleAcceptDeliveryButton() {
    var editData = {
      AcceptDelivery: "",
    };
    if (storeContext.acceptDelivery !== "true") {
      editData.AcceptDelivery = "true";
    }
    await axios.put(
      `${BASE_API}api/Store/UpdateStoreDeliveryOrderStatus`,
      editData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          Application: APPLICATION,
        },
      }
    );
    var newStoreContext = storeContext;
    if (storeContext.acceptDelivery !== "true") {
      newStoreContext.acceptDelivery = "true";
    } else {
      newStoreContext.acceptDelivery = "";
    }
    props.setStoreContext(newStoreContext);
    localStorage.setItem("storeContext", JSON.stringify(newStoreContext));

    setIsDeliveryActive(!isDeliveryActive);
  }

  const togglePageLock = () => {
    setIsPageLocked((isPageLocked) => !isPageLocked);
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 2 seconds
    }, 2000);
    if (isPageLocked) {
      toast.success("Page is Unlocked.");
    } else {
      toast.error("Page is Locked.");
    }
  };

  const throwLockedError = () => {
    if (disabled === false) {
      toast.error("Page is Locked.");
    }
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false); // Enable the button after 2 seconds
    }, 2000);
  };

  return (
    <div className="locked_container card-body">
      <div onClick={togglePageLock}>
        <>
          {isDisabled ? (
            <></>
          ) : (
            <>
              {isPageLocked ? (
                <AiFillLock
                  style={{ color: "red" }}
                  size={30}
                  disabled={isDisabled}
                />
              ) : (
                <AiFillUnlock
                  style={{ color: "green" }}
                  size={30}
                  disabled={isDisabled}
                />
              )}
            </>
          )}
        </>
      </div>
      {isPageLocked ? (
        <>
          <div className="locked">
            <div className="shadow"></div>
            <div className="accept_orders field">
              <div className="fs-3">Accept All Orders</div>
              <button
                className={`toggle-button ${
                  storeContext.isActive ? "active" : "inactive"
                }`}
                onClick={throwLockedError}
              >
                {storeContext.isActive ? "Active" : "Inactive"}
              </button>
            </div>
            <div className="accept_delivery_orders field">
              <div className="fs-3">Accept Delivery Orders</div>
              <button
                className={`toggle-button ${
                  storeContext.acceptDelivery ? "active" : "inactive"
                }`}
                onClick={throwLockedError}
              >
                {storeContext.acceptDelivery ? "Active" : "Inactive"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="open">
            <div className="accept_orders field">
            <div className="fs-3">Accept All Orders</div>
            <button
                className={`toggle-button ${
                  storeContext.isActive ? "active" : "inactive"
                }`}
                onClick={toggleAcceptOrdersButton}
              >
                {storeContext.isActive ? "Active" : "Inactive"}
              </button>
            </div>
            <div className="accept_delivery_orders field">
              <div className="fs-3">Accept Delivery Orders</div>
              <button
                className={`toggle-button ${
                  storeContext.acceptDelivery ? "active" : "inactive"
                }`}
                onClick={toggleAcceptDeliveryButton}
              >
                {storeContext.acceptDelivery ? "Active" : "Inactive"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default StoreStatus;
