import React from "react";

function UserOrders(props) {
  return (
    <div className="orders_container">
      {props.userOrders ? (
        <>
          {props.userOrders?.map((i) => (
            <div className="card order_dropdown" key={i.orderID}>
              <div className="card-header">
                <div
                  className="order_header_details"
                  Style="justify-content: flex-start !important;"
                >
                  <div className="orderNumber">{"#" + i.orderNumber}</div>
                  <div className="orderType">{i.orderType}</div>
                  <div className="date">{i.requestedDate}</div>
                </div>
              </div>
              <div id={`body_${i.orderID}`} className="card-body">
                {i.orderType === "Delivery" && (
                  <div className="delivery_address">
                    {"Address: " +
                      i.streetAddress +
                      " " +
                      i.city +
                      " " +
                      i.stateName}
                  </div>
                )}
                <div className={`order order_${i.orderID}`}>
                  {i.items?.map((item) => (
                    <div key={item.orderItemID} className="item">
                      <div className="left">
                        <div className="item_name">
                          {item.sizeCode ? (
                            <h6>
                              {item.itemQuantity +
                                "x - " +
                                item.itemName +
                                " - " +
                                item.sizeCode}
                            </h6>
                          ) : (
                            <h6>
                              {item.itemQuantity + "x - " + item.itemName}
                            </h6>
                          )}
                        </div>
                        {item.extras?.map((extra) => (
                          <div
                            key={extra.orderItemExtraID}
                            className="item_extra"
                          >
                            {"-" + extra.extraName}
                            {extra.pizzaHalfCode && " " + extra.pizzaHalfCode}
                          </div>
                        ))}
                        {item.itemSpecialRequests && (
                          <div className="item_extra specialRequest">
                            {'"' + item.itemSpecialRequests + '"'}
                          </div>
                        )}
                      </div>
                      <div className="right">{"$" + item.itemTotalPrice}</div>
                    </div>
                  ))}

                  <div className="order_totals">
                    <div className="left">
                      <div>Subtotal</div>
                      <div>Tax</div>
                      <div>Tip</div>
                      <div>Fee</div>
                      <div>Final Total</div>
                    </div>
                    <div className="right">
                      <div className="price">${i.orderSubtotal.toFixed(2)}</div>
                      <div className="price">${i.orderTax.toFixed(2)}</div>
                      <div className="price">${i.orderTip.toFixed(2)}</div>
                      <div className="price">$0.50</div>
                      <div className="price">${i.orderTotal.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div>No Orders</div>
      )}
    </div>
  );
}

export default UserOrders;
