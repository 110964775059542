export const checkIfItemExistsInCart = (cartItems, newItem) => {
  var returnValue = {
    itemExists: false,
    cartItems: [],
  };
  if (cartItems) {
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i].CartItem_MenuItemID === newItem.CartItem_MenuItemID) {
        //cartItems[i].Same = false;
        //if newItemExtras and cartItems[i].Extras are both empty they are the same item
        if (newItem.Extras.length === 0 && cartItems[i].Extras.length === 0) {
          cartItems[i].Same = true;

          returnValue = {
            itemExists: true,
            cartItems: cartItems,
          };
          return returnValue;
        } else {
          //loop through cartItems[i].Extras and newItem.Extras to compare all extras in both
          var sameExtrasCount = 0;
          for (var j = 0; j < cartItems[i].Extras.length; j++) {
            if (newItem.Extras.length === 0) {
              returnValue = {
                itemExists: false,
                cartItems: cartItems,
              };
              return returnValue;
            }
            for (var x = 0; x < newItem.Extras.length; x++) {
              if (
                cartItems[i].Extras[j].CartExtra_MenuItemExtraID ===
                  newItem.Extras[x].CartExtra_MenuItemExtraID &&
                cartItems[i].Extras[j].ExtraDescription ===
                  newItem.Extras[x].ExtraDescription
              ) {
                sameExtrasCount = sameExtrasCount + 1;
              }
            }
          }
          //check if sameExtrasCount === the number of extras in newItem
          if (sameExtrasCount === newItem.Extras.length) {
            cartItems[i].Same = true;
            ////////
            returnValue = {
              itemExists: true,
              cartItems: cartItems,
            };
            return returnValue;
          }
        }
      }
    }

    //after searching through all cartItems if the newItem does not have same ID the item is not in the cart
    returnValue = {
      itemExists: false,
      cartItems: cartItems,
    };
    return returnValue;
  } else {
    returnValue = {
      itemExists: false,
      cartItems: cartItems,
    };
    return returnValue;
  }
};
