import React from 'react';
import "../screens/home/home.scss";

function Loading() {
  return (
    <div className='loading_container'>
      <div class="loading" />
    </div>
  )
}

export default Loading;
