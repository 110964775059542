import { formatEST, formatTime, formatShortTime } from "./FormatTime";

export const getNext14DaysTimes = (props, storeContext) => {
  const date = new Date();

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var dayName = daysOfWeek[date.getDay()];

  if(props.selectedDay) {
    var dateName = new Date(props.selectedDay.date);
    var name = daysOfWeek[dateName.getDay()];
    dayName = name;
  }

  var openH;
  var openM;
  var closeH;
  var closeM;

  if (props.orderType === "Pickup") {
    for (var i = 0; i < storeContext.hours.length; i++) {
      if (storeContext.hours[i].dayOfWeek === dayName) {
        const openhour = storeContext.hours[i].openTime.substring(0, 2);
        const openminute = storeContext.hours[i].openTime.substring(3, 5);
        openH = openhour;
        openM = openminute;
        const closehour = storeContext.hours[i].closeTime.substring(0, 2);
        const closeminute = storeContext.hours[i].closeTime.substring(3, 5);
        closeH = closehour;
        closeM = closeminute;
      }
    }
  } else {
    for (var j = 0; j < storeContext.deliveryHours.length; j++) {
      if (storeContext.deliveryHours[j].dayOfWeek === dayName) {
        const openhour = storeContext.deliveryHours[j].openTime.substring(0, 2);
        const openminute = storeContext.deliveryHours[j].openTime.substring(3, 5);
        openH = openhour;
        openM = openminute;
        const closehour = storeContext.deliveryHours[j].closeTime.substring(0, 2);
        const closeminute = storeContext.deliveryHours[j].closeTime.substring(3, 5);
        closeH = closehour;
        closeM = closeminute;
      }
    }
  }

  const startTime = new Date();
  if (props.selectedDay === undefined) {
    startTime.setHours(openH);
    startTime.setMinutes(openM);
  } else if (props.selectedDay !== undefined) {
    if (props.selectedDay.isToday === true) {
      startTime.setHours(startTime.getHours() + 1); // set start time to 1 hour from now
      startTime.setMinutes(0);
    } else {
      startTime.setHours(openH);
      startTime.setMinutes(openM);
    }
  }
  startTime.setSeconds(0);
  startTime.setMilliseconds(0);

  const endTime = new Date();
  endTime.setHours(closeH);
  endTime.setMinutes(closeM);
  endTime.setSeconds(0);
  endTime.setMilliseconds(0);

  const timeSlotDuration = 15; // in minutes

  const slots = [];
  let slotTime = new Date(startTime);

  while (slotTime <= endTime) {
    const formattedTime = formatTime(slotTime);
    const formattedEST = formatEST(slotTime);
    const time = formatShortTime(formattedTime);

    slots.push({
      time: formattedTime,
      est: formattedEST,
      shortTime: time,
    });
    slotTime.setTime(slotTime.getTime() + timeSlotDuration * 60000);
  }

  return slots;
};

export const getNext14Days = (storeContext) => {
  // #region DATE
  // Get today's date
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow

  // Get today's date
  const today = new Date();

  // Create an array of 14 days starting from today
  const next14Days = [...Array(14)].map((_, index) => {
    const date = new Date(today);
    date.setDate(today.getDate() + index);
    return date;
  });

  // Filter out closed days
  const filteredNext14Days = next14Days.filter(date => {
    const dateString = date.toISOString().slice(0, 10); // Get date in format "YYYY-MM-DD"
    return !storeContext.storeClosures.some(closure => closure.dateClosed.includes(dateString));
  });

  // Format the dates as "Day of the week, month, day of the month, year" and assign an ID and isToday property to each date
  const formattedDates = filteredNext14Days.map((date, index) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[date.getMonth()];
    const dayOfMonth = `${date.getDate()}`.padStart(2, "0");
    const year = date.getFullYear();
    const utcDate = date.toISOString().slice(0, 19) + "Z"; // Get UTC date in format "YYYY-MM-DDTHH:MM:SSZ"
    const isToday = date.toDateString() === today.toDateString();
    return {
      id: index,
      date: `${dayOfWeek}, ${monthName} ${dayOfMonth}, ${year}`,
      mysqlDate: utcDate,
      isToday: isToday,
    };
  });

  return formattedDates;
  // #endregion
  //
};

