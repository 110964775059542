import React from "react";
import "./footer.scss";

function FooterHome() {

  return (
    <div className="footer footer_home">
      <div className="empowered">
        <h5 className="content">
          <div>Powered by</div>
          <a href="https://enterprisewebsolutions.co">
            Enterprise Web Solutions &#169;
          </a>
        </h5>
      </div>
      <div className="terms">
        <div className="cards">
          <img src="/pictures/visa.png" alt="" />
          <img src="/pictures/mastercard.png" alt="" />
          <img src="/pictures/americanexpress.png" alt="" />
          <img src="/pictures/discover.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default FooterHome;