import React, { useState } from "react";
import {
  IoIosMail,
  IoMdPhonePortrait,
} from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { handleGuestPhoneNumberChange } from "../../utils/authenticate/HandleGuestPhoneNumberChange";
import axios from "axios";
import { APPLICATION, BASE_API, STORE_NAME_CODE } from "../../constants";
import { toast } from "react-toastify";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";

function Guest(props) {
  const [guestName, setGuestName] = useState("");
  const [guestPhoneNumber, setGuestPhoneNumber] = useState("");
  const [guestEmail, setGuestEmail] = useState("");

  async function handleGuestPhoneNumber(e) {
    var guestNumber = handleGuestPhoneNumberChange(e);
    setGuestPhoneNumber(guestNumber);
  }

  async function continueAsGuest() {
    if (!guestName || !guestPhoneNumber) {
      toast.error("Please enter a name and phone number for your order!");
      return;
    }
    var newAppContext;
    if (props.appContext.userInfo === null) {
      var userGuid = CreateGuid();
      var newCart = {};
      if (
        props.appContext.cart === null ||
        props.appContext.cart.OrderType === undefined
      ) {
        newCart = {
          CartID: userGuid,
          OrderType: null,
        };
      } else {
        newCart = {
          CartID: userGuid,
          OrderType: props.appContext.cart.OrderType,
        };
      }
      var newUser = {};
      const response = await axios.post(
        `${BASE_API}api/User/GetGuestToken`,
        userGuid,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      newUser = {
        userID: userGuid,
        token: response.data.token,
        role: "GuestUser",
        firstName: guestName,
        lastName: " .",
        phoneNumber: guestPhoneNumber,
        email: guestEmail,
        storeName: STORE_NAME_CODE,
      };
      newAppContext = {
        userInfo: newUser,
        cart: newCart,
        cartItems: props.appContext.cartItems,
      };
    } else {
      newUser = {
        userID: props.appContext.userInfo.userID,
        token: props.appContext.userInfo.token,
        role: "GuestUser",
        firstName: guestName,
        lastName: " .",
        phoneNumber: guestPhoneNumber,
        email: guestEmail,
        storeName: STORE_NAME_CODE,
      };
      newAppContext = {
        userInfo: newUser,
        cart: props.appContext.cart,
        cartItems: props.appContext.cartItems,
      };
    }
    props.setAppContext(newAppContext);
    localStorage.setItem("appContext", JSON.stringify(newAppContext));

    if (props.setUserChoice !== undefined) {
      props.setUserChoice(true);
    }
    props.setShowAuthenticateModal(false);
  }
  
  async function resetPassword() {
    props.setShowAuthenticateModal(false);
    props.setShowResetPasswordModal(true);
  }

  return (
    <div className="register_page loginregisterModal guestOrder">
      <div className="container guest_inputs">
        <div className="register_notice">
          Create an account for quicker and more convenient checkout experiences
          in the future!
        </div>
        <div className="input_fields">
          <label>
            <BsFillPersonFill style={{ width: "2em", height: "2em" }} />
          </label>
          <input
            type="text"
            placeholder="Name"
            onChange={(e) => setGuestName(e.target.value)}
          />
        </div>
        {props.storeContext.mxMerchant != "true" && (
          <div className="input_fields">
            <label>
              <IoIosMail style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="text"
              placeholder="Email for receipt"
              onChange={(e) => setGuestEmail(e.target.value)}
            />
          </div>
        )}
        <div className="input_fields">
          <label>
            <IoMdPhonePortrait style={{ width: "2em", height: "2em" }} />
          </label>
          <input
            type="text"
            placeholder="Phone Number"
            pattern="\d{3}-\d{3}-\d{4}|\d{10}"
            value={guestPhoneNumber}
            onChange={handleGuestPhoneNumber}
          />
        </div>

        <div className="submit">
          <button type="button" className="user" onClick={continueAsGuest}>
            Continue as Guest
          </button>
        </div>

        {/* <div className="forgot_password" onClick={resetPassword}>
            Forgot My Password
          </div> */}
      </div>
    </div>
  );
}

export default Guest;
