import React from "react";
import "../screens/home/home.scss";
import { STORE_NAME_CODE } from "../constants";
//         src={`./pictures/${STORE_NAME_CODE}.png`}

function LoadingLogo() {
  // Assume the URL is obtained dynamically or from props
  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);

  // Get the pathname after the query string
  const pathname = url.pathname;

  // Split the pathname by '/' and get the first part
  const firstPart = pathname.split('/')[1];

  return (
    <div className="loading_container">
      <img
        src={`/pictures/${firstPart}.png`}
        alt=""
        class="loading_logo"
      />
    </div>
  );
}

export default LoadingLogo;
