import React, { useContext, useState } from "react";
import "./settings.scss";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../../constants";
import ApplicationContext from "../../../ApplicationContext";
import Loading from "../../../tools/Loading";

function StoreMenuSettings(props) {
  const appContext = useContext(ApplicationContext);

  const [isPageLocked, setIsPageLocked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  async function removeFromList(menuItemID, itemCategory) {
    var elementToMakeInActive = document.getElementById(`item_${menuItemID}`);

    if (elementToMakeInActive) {
      setIsLoading(true);

      var itemData = {};
      itemData.menuItemID = menuItemID;
      itemData.isActive = 0;
      await axios.put(`${BASE_API}api/Store/UpdateMenuItemActivity`, itemData, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          Application: APPLICATION,
        },
      });

      props.menu[itemCategory] = props.menu[itemCategory].map((item) => {
        if (item.menuItemID === menuItemID) {
          return { ...item, active: "0" };
        }
        return item;
      });

      elementToMakeInActive.classList.remove("Active");

      // Update the state with the modified menu
      props.setMenu(props.menu);
      setIsLoading(false);
    } else {
      toast.error("Error changing item status");
    }
  }

  async function addToList(menuItemID, itemCategory) {
    var elementToMakeActive = document.getElementById(`item_${menuItemID}`);

    if (elementToMakeActive) {
      setIsLoading(true);

      var itemData = {};
      itemData.menuItemID = menuItemID;
      itemData.isActive = 1;
      await axios.put(`${BASE_API}api/Store/UpdateMenuItemActivity`, itemData, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          Application: APPLICATION,
        },
      });

      props.menu[itemCategory] = props.menu[itemCategory].map((item) => {
        if (item.menuItemID === menuItemID) {
          return { ...item, active: "1" };
        }
        return item;
      });

      elementToMakeActive.classList.add("Active");

      // Update the state with the modified menu
      props.setMenu(props.menu);
      setIsLoading(false);
    } else {
      toast.error("Error changing item status");
    }
  }

  const togglePageLock = () => {
    setIsPageLocked((isPageLocked) => !isPageLocked);
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 2 seconds
    }, 2000);
    if (isPageLocked) {
      toast.success("Page is Unlocked.");
    } else {
      toast.error("Page is Locked.");
    }
  };

  return (
    <div className="menu_items_container">
      {props.menu ? (
        <>
          <div onClick={togglePageLock} className="mb-2">
            <>
              {isDisabled ? (
                <></>
              ) : (
                <>
                  {isPageLocked ? (
                    <AiFillLock
                      style={{ color: "red" }}
                      size={30}
                      disabled={isDisabled}
                    />
                  ) : (
                    <AiFillUnlock
                      style={{ color: "green" }}
                      size={30}
                      disabled={isDisabled}
                    />
                  )}
                </>
              )}
            </>
          </div>

          {isPageLocked ? (
            <>
              <div className="locked">
                <div className="shadow"></div>
                {Object.keys(props.menu).map((categoryName) => (
                  <div key={categoryName}>
                    <h2 className="mb-2">{categoryName}</h2>
                    {props.menu[categoryName].map((item) => (
                      <div
                        key={item.menuItemID}
                        className="itemCard card mb-1 py-1 px-3"
                      >
                        <div className="itemName_container">
                          <div className="item_name">{item.itemName}</div>
                          {item.itemSize && (
                            <div className="item_size mx-1">
                              ({item.itemSize})
                            </div>
                          )}
                        </div>

                        <div className="button_container">
                          <div className="mx-2">Active:</div>

                          <>
                            {item.active === "1" ? (
                              <div className="active_button Active">
                                <FaCheck />
                              </div>
                            ) : (
                              <div className="active_button"></div>
                            )}
                          </>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="open">
                {Object.keys(props.menu).map((categoryName) => (
                  <div key={categoryName}>
                    <h2 className="mb-2">{categoryName}</h2>
                    {props.menu[categoryName].map((item) => (
                      <div
                        key={item.menuItemID}
                        className="itemCard card mb-1 py-1 px-3"
                      >
                        <div className="itemName_container">
                          <div className="item_name">{item.itemName}</div>
                          {item.itemSize && (
                            <div className="item_size mx-1">
                              ({item.itemSize})
                            </div>
                          )}
                        </div>

                        <div className="button_container">
                          <div className="mx-2">Active:</div>

                          {isLoading ? (
                            <Loading />
                          ) : (
                            <>
                              {item.active === "1" ? (
                                <div
                                  className="active_button Active"
                                  onClick={() =>
                                    removeFromList(
                                      item.menuItemID,
                                      item.itemCategory
                                    )
                                  }
                                  id={`item_${item.menuItemID}`}
                                >
                                  <FaCheck />
                                </div>
                              ) : (
                                <div
                                  className="active_button"
                                  onClick={() =>
                                    addToList(
                                      item.menuItemID,
                                      item.itemCategory
                                    )
                                  }
                                  id={`item_${item.menuItemID}`}
                                ></div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default StoreMenuSettings;
