import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import ApplicationContext from "../../ApplicationContext";
import MenuContext from "../../MenuContext";
import { APPLICATION, BASE_API } from "../../constants";
import AreYouSure from "../admin/areYouSure/AreYouSure";
import EditRewardItemModal from "./EditRewardItemModal";

function RewardTierItemModal(props) {
  const appContext = useContext(ApplicationContext);
  const menuContext = useContext(MenuContext);
  const [toggleAddNewItems, setToggleAddNewItems] = useState(false);

  const [showAreYouSureDeleteRewardItem, setShowAreYouSureDeleteRewardItem] =
    useState(false);
  const [itemToDelete, setItemToDelete] = useState({});

  const [showEditRewardItemModal, setShowEditRewardItemModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState({});

  const [otherRewardTiers, setOtherRewardTiers] = useState();

  const [categorizedMenuItems, setCategorizedMenuItems] = useState();

  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});

  const [discountRewardPrice, setDiscountRewardPrice] = useState(0);

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const toggleSubCategory = (subCategory) => {
    setExpandedSubCategories((prev) => ({
      ...prev,
      [subCategory]: !prev[subCategory],
    }));
  };

  useEffect(() => {
    const categorizedArray = [];
    const categorizedMap = {};

    menuContext.items.forEach((item) => {
      const { itemCategory, itemSubCategory } = item;

      if (!categorizedMap[itemCategory]) {
        categorizedMap[itemCategory] = {
          category: itemCategory,
          items: [],
          subCategories: {},
        };
      }

      if (itemSubCategory) {
        if (!categorizedMap[itemCategory].subCategories[itemSubCategory]) {
          categorizedMap[itemCategory].subCategories[itemSubCategory] = [];
        }
        categorizedMap[itemCategory].subCategories[itemSubCategory].push(item);
      } else {
        categorizedMap[itemCategory].items.push(item);
      }
    });

    Object.keys(categorizedMap).forEach((category) => {
      const categoryObj = categorizedMap[category];
      categoryObj.subCategories = Object.keys(categoryObj.subCategories).map(
        (subCategory) => ({
          subCategory,
          items: categoryObj.subCategories[subCategory],
        })
      );
      categorizedArray.push(categoryObj);
    });

    setCategorizedMenuItems(categorizedArray);
  }, []);

  async function closeRewardTierModal() {
    props.setShowRewardItemModal(false);
  }

  async function addNewItems() {
    setToggleAddNewItems(!toggleAddNewItems);
  }

  async function deleteRewardItem(item) {
    setItemToDelete(item);
    setShowAreYouSureDeleteRewardItem(true);
  }

  async function editRewardItem(item) {
    var otherTiers = [];
    for (var x = 0; x < props.allRewards.length; x++) {
      if (props.allRewards[x].points != props.currentTier.points) {
        otherTiers.push(props.allRewards[x]);
      }
    }

    setItemToEdit(item);
    setOtherRewardTiers(otherTiers);
    setShowEditRewardItemModal(true);
  }

  async function addRewardItem(item) {
    var rewardItem_StoreRewardID = props.currentTier.storeRewardID;
    var rewardItem_MenuItemID = item.menuItemID;
    var rewardItemPrice = 0;

    try {
      await axios.post(
        `${BASE_API}AddRewardItem/${rewardItem_StoreRewardID}/${rewardItem_MenuItemID}/${rewardItemPrice}`,
        { rewardItem_StoreRewardID, rewardItem_MenuItemID, rewardItemPrice },
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );

      props.getRewards();
      props.setShowRewardItemModal(false);
    } catch (error) {
      toast.error("Error. Please try again.");
    }
  }

  async function addDiscountReward() {
    if (!discountRewardPrice || discountRewardPrice < 1) {
      toast.error("Error. Please enter a valid price.");
    } else {
      try {
        var rewardItem_StoreRewardID = props.currentTier.storeRewardID;
        var rewardItem_MenuItemID = -1;
        var rewardItemPrice = discountRewardPrice;
        await axios.post(
          `${BASE_API}AddDiscountRewardItem/${rewardItem_StoreRewardID}/${rewardItem_MenuItemID}/${rewardItemPrice}`,
          { rewardItem_StoreRewardID, rewardItem_MenuItemID, rewardItemPrice },
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );

        props.getRewards();
        props.setShowRewardItemModal(false);
      } catch (error) {
        toast.error("Error. Please try again.");
      }
    }
  }

  return (
    <>
      {showAreYouSureDeleteRewardItem && (
        <AreYouSure
          showAreYouSure={showAreYouSureDeleteRewardItem}
          setShowAreYouSure={setShowAreYouSureDeleteRewardItem}
          setShowRewardItemModal={props.setShowRewardItemModal}
          getRewards={props.getRewards}
          itemToDelete={itemToDelete}
          type={"delete_reward_item"}
        />
      )}

      {showEditRewardItemModal && (
        <EditRewardItemModal
          showEditRewardItemModal={showEditRewardItemModal}
          setShowEditRewardItemModal={setShowEditRewardItemModal}
          setShowRewardItemModal={props.setShowRewardItemModal}
          itemToEdit={itemToEdit}
          currentTier={props.currentTier}
          rewardItems={props.rewardItems}
          getRewards={props.getRewards}
          allRewards={props.allRewards}
          otherRewardTiers={otherRewardTiers}
        />
      )}

      <Modal className="rewardItemModal" show={props.showRewardItemModal}>
        <Modal.Body>
          <Modal.Header className="d-flex justify-content-end">
            <FaWindowClose
              onClick={closeRewardTierModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          <Modal.Title>
            Edit Reward Items {" - "} {props.currentTier.points} Points
          </Modal.Title>

          <div className="reward_modal_body">
            <div className="new_items_container">
              <div className="new_items_button" onClick={addNewItems}>
                {toggleAddNewItems === true ? (
                  <>SEE CURRENT ITEMS</>
                ) : (
                  <>ADD NEW ITEMS</>
                )}
              </div>
            </div>

            {toggleAddNewItems === true ? (
              <>
                <div className="add_new_reward_items_container">
                  <div className="reward_title">Add New Items</div>

                  <div className="reward_items_container">
                    <div className="add_discount_container">
                      <div className="header_container">
                        <div className="add_discount_header">
                          Add Custom Discount:
                        </div>
                        <input
                          placeholder="Enter the Discount Price"
                          onChange={(e) =>
                            setDiscountRewardPrice(e.target.value)
                          }
                        />
                        <div className="submit" onClick={addDiscountReward}>
                          Submit
                        </div>
                      </div>
                    </div>

                    {categorizedMenuItems?.map((cat) => (
                      <div key={cat.category} className="reward_item">
                        <div
                          className="header_container"
                          onClick={() => toggleCategory(cat.category)}
                        >
                          <div className="header">{cat.category}</div>
                          <div className="button">^</div>
                        </div>

                        {expandedCategories[cat.category] && (
                          <>
                            {cat.items?.map((item) => (
                              <div key={item.itemName} className="reward_item">
                                <div className="item_container">
                                  <div className="item_name_container">
                                    <div className="name">{item.itemName}</div>
                                    {item.itemSize && (
                                      <div className="size">
                                        {" - ("}
                                        {item.itemSize}
                                        {")"}
                                      </div>
                                    )}
                                    <div className="price">
                                      {" - "}${item.itemPrice}
                                    </div>
                                  </div>
                                  <div
                                    className="add_button"
                                    onClick={() => addRewardItem(item)}
                                  >
                                    Add
                                  </div>
                                </div>
                              </div>
                            ))}

                            {cat.subCategories?.map((subCat) => (
                              <div
                                key={subCat.subCategory}
                                className="reward_item"
                              >
                                <div
                                  className="header_container"
                                  onClick={() =>
                                    toggleSubCategory(subCat.subCategory)
                                  }
                                >
                                  <div className="header">
                                    {subCat.subCategory}
                                  </div>
                                  <div className="button">^</div>
                                </div>

                                {expandedSubCategories[subCat.subCategory] && (
                                  <>
                                    {subCat.items?.map((item) => (
                                      <div
                                        key={item.itemName}
                                        className="reward_item"
                                      >
                                        <div className="item_container">
                                          <div className="item_name_container">
                                            <div className="name">
                                              {item.itemName}
                                            </div>
                                            {item.itemSize && (
                                              <div className="size">
                                                {" - ("}
                                                {item.itemSize}
                                                {")"}
                                              </div>
                                            )}
                                            <div className="price">
                                              {" - "}${item.itemPrice}
                                            </div>
                                          </div>
                                          <div className="add_button" onClick={() => addRewardItem(item)}>Add</div>
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="current_reward_container">
                  {props.currentTier.items.length < 1 ? (
                    <div className="reward_title">No Items in this Tier</div>
                  ) : (
                    <div className="reward_title">Current Reward Items</div>
                  )}

                  {props.rewardItems.items?.map((item, index) => (
                    <div key={item.storeRewardItemID} className="reward_item">
                      {item.rewardItem_MenuItemID < 0 ? (
                        <>
                          <div className="item">
                            <div className="item_details">
                              <div className="name">
                                ${item.rewardItemPrice} Discount
                              </div>
                            </div>
                            <div className="reward_item_options">
                              <div
                                className="edit button"
                                onClick={() => editRewardItem(item)}
                              >
                                Edit Item
                              </div>
                              <div
                                className="delete button"
                                onClick={() => deleteRewardItem(item)}
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="item">
                            <div className="item_details">
                              <div className="name">
                                {item.itemSize ? (
                                  <>
                                    {item.itemName + " (" + item.itemSize + ") - "}
                                  </>
                                ) : (
                                  <>
                                    {item.itemName + " - "}

                                  </>
                                )}
                              </div>
                              <div className="price">
                                ${item.rewardItemPrice}
                              </div>
                            </div>
                            <div className="reward_item_options">
                              <div
                                className="edit button"
                                onClick={() => editRewardItem(item)}
                              >
                                Edit Item
                              </div>
                              <div
                                className="delete button"
                                onClick={() => deleteRewardItem(item)}
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RewardTierItemModal;
