export const updateCartValues = (state, newItem, itemExists) => {
  var extraTotal = 0.0;
  for (var i = 0; i < newItem.Extras.length; i++) {
    extraTotal = extraTotal + newItem.Extras[i].ExtraPrice;
  }
  var cartData = {};
  var num;
  if (!state.cart || !state.cart.CartPrice) {
    //if cart is null make our price
    num = (
      newItem.ItemPrice * newItem.ItemQuantity +
      extraTotal * newItem.ItemQuantity
    ).toFixed(2);
    const fixedNumber = parseFloat(num);
    cartData = {
      CartID: state.userInfo.userID,
      OrderType: null,
      CartPrice: fixedNumber,
    };
  } else if (state.cart.CartPrice && itemExists.itemExists === true) {
    var otherItemsTotal = 0.0;
    //take previous cart and add new cart to it
    for (var k = 0; k < state.cartItems.length; k++) {
      if (state.cartItems[k].Same !== true) {
        //otherItemsTotal = otherItemsTotal + state.cartItems[k].ItemPrice;
        var itemTotal = state.cartItems[k].ItemPrice;

        for (var z = 0; z < state.cartItems[k].Extras.length; z++) {
          itemTotal = itemTotal + state.cartItems[k].Extras[z].ExtraPrice;
          //otherItemsTotal = otherItemsTotal + state.cartItems[k].Extras[z].ExtraPrice;
        }
        itemTotal = itemTotal * state.cartItems[k].ItemQuantity;
        otherItemsTotal = parseFloat(otherItemsTotal) + parseFloat(itemTotal);
      }
    }
    state.cart.CartPrice = otherItemsTotal;
    num =
      state.cart.CartPrice +
      parseFloat(((newItem.ItemPrice + extraTotal) * newItem.ItemQuantity).toFixed(2));
    const fixedNumber = parseFloat(num);
    cartData = {
      CartID: state.userInfo.userID,
      OrderType: state.cart.OrderType,
      CartPrice: fixedNumber,
    };
  } else if (state.cart.CartPrice && itemExists.itemExists !== true) {
    //take previous cart and add new cart to it
    num =
      state.cart.CartPrice +
      newItem.ItemPrice * newItem.ItemQuantity +
      extraTotal * newItem.ItemQuantity;
    const fixedNumber = parseFloat(num.toFixed(2));
    cartData = {
      CartID: state.userInfo.userID,
      OrderType: state.cart.OrderType,
      CartPrice: fixedNumber,
    };
  }

  return cartData;
};
