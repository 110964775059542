import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import StoreContext from "../../StoreContext";
import { toast } from "react-toastify";
import axios from "axios";
import ApplicationContext from "../../ApplicationContext";
import { APPLICATION, BASE_API } from "../../constants";

function AddRewardTierModal(props) {
    const storeContext = useContext(StoreContext);
    const appContext = useContext(ApplicationContext);
    const [rewardName, setRewardName] = useState("Added In Store");
    const [rewardPoints, setRewardPoints] = useState(0);

    async function closeRewardTierModal() {
        props.setShowRewardTierModal(false);
    }

    async function addRewardTier() {
        if (rewardPoints < 1) {
            toast.error("Please enter an amount of points");
        } else {
            try {
                await axios.post(
                    `${BASE_API}AddRewardTier/${rewardName}/${rewardPoints}`,
                    { rewardName, rewardPoints },
                    {
                        headers: {
                            Authorization: `Bearer ${appContext.userInfo.token}`,
                            Application: APPLICATION,
                        },
                    }
                );

                props.getRewards();
                props.setShowRewardTierModal(false);
            } catch (error) {
                toast.error("Error. Please try again.");
            }
        }
    }

    return (
        <>
            <Modal className="rewardItemModal" show={props.showRewardTierModal}>
                <Modal.Body>
                    <Modal.Header className="d-flex justify-content-end">
                        <FaWindowClose
                            onClick={closeRewardTierModal}
                            style={{ width: "2em", height: "2em" }}
                            className="text-danger"
                        />
                    </Modal.Header>
                    <Modal.Title>Add New Reward Tier</Modal.Title>

                    <div className="add_reward_inputs">
                        <div className="input_fields">
                            <label className="label">
                                Points to reach this tier:
                            </label>
                            <input
                                type="text"
                                placeholder="Every $1 spent by the Customer = 1 Reward Point"
                                onChange={(e) => setRewardPoints(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="add_tier_button" onClick={addRewardTier}>Submit</div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddRewardTierModal;