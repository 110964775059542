import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import stateAbbreviations from "../data/stateAbbreviations";

function GoogleMapsInputHome(props) {
  const [address, setAddress] = useState("");

  // Function to handle changes in the input field
  const handleChange = (event) => {
    setAddress(event.target.value);
  };

  // Function to handle selection from autocomplete suggestions
  const handlePlaceSelect = async (place) => {
    if (place.name != "") {
      // Extracting address components
      const addressComponents = {};
      place.address_components.forEach((component) => {
        addressComponents[component.types[0]] = component.long_name;
      });

      // Building the complete address
      const formattedAddress = {
        street: addressComponents.street_number
          ? addressComponents.street_number + " " + addressComponents.route
          : addressComponents.route,
        city: addressComponents.locality,
        state:
          stateAbbreviations[addressComponents.administrative_area_level_1] ||
          addressComponents.administrative_area_level_1,
        zipCode: addressComponents.postal_code,
        apartment: addressComponents.subpremise || null,
      };

      props.setSearchedAddress(formattedAddress);
      setAddress(formattedAddress.street + ", " + formattedAddress.city + " " + formattedAddress.state)
    }
  }

  // Initialize Google Autocomplete service
  const initAutocomplete = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete-input"),
      { types: ["geocode"] } // Limit to addresses only
    );

    // Listen for place selection
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      handlePlaceSelect(place);
    });
  };

  // Load Google Maps API script asynchronously
  const loadGoogleMapsScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0286rXJ9WGiFAPZO8oQyWsh4M4fV0ag8&libraries=places`;
    script.onload = initAutocomplete;
    document.head.appendChild(script);
  };

  // Load Google Maps API script when component mounts
  React.useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  return (
    <div className="google_maps_input_container">
      <div className="map_pin_container" style={{ padding: "2px 10px" }}>
        <FaMapMarkerAlt className="map_pin" />
      </div>
      <input
        id="autocomplete-input"
        type="text"
        value={address}
        onChange={handleChange}
        placeholder="Enter your address"
      />
      <div className="arrow_container" style={{ padding: "2px 14px" }}>
        <FaArrowAltCircleRight className="arrow" />
      </div>
    </div>
  );
}

export default GoogleMapsInputHome;
