import React, { useState, useContext, useEffect } from "react";
import ApplicationContext from "../../ApplicationContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaWindowClose } from "react-icons/fa";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "./cart.scss";
import StoreContext from "../../StoreContext";
import CartItems from "./CartItems";

function Cart(props) {
  const navigate = useNavigate();

  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    if (storeContext.color) {
      const element = document.getElementById("cart_screen");

      if (element) {
        element.style.backgroundColor = storeContext.color;
      }
      setShowCart(true);
    }
  }, []);

  function toggleCartBody() {
    if (
      document.getElementById("cart_popup").classList.contains("collapse") ===
      true
    ) {
      document.getElementById("cart_popup").classList.remove("collapse");
      props.setCartOpen(true);
    } else {
      document.getElementById("cart_popup").classList.add("collapse");
      props.setCartOpen(false);
    }
  }

  function checkout() {
    const pathParts = window.location.pathname.split('/');
    const newPath = `/${pathParts[1]}/order`; // Keep the first part and add '/order'

    if (storeContext.mxMerchant === "true") {
      if (
        !appContext.userInfo.role ||
        !appContext.userInfo.firstName ||
        appContext.userInfo.role === "Guest"
      ) {
        props.setAuthKey("Guest");
        props.setShowAuthenticateModal(true);
      } else if (appContext.cart.CartPrice >= storeContext.orderMin) {
        if (pathParts.length > 2 && pathParts[2] === "rewards") {
          navigate(newPath);
        } else {
          navigate("order");
        }
      } else {
        toast.error(`Mimimum order amount is $${storeContext.orderMin}.`);
      }
    } else {
      if (
        !appContext.userInfo.role ||
        !appContext.userInfo.email ||
        !appContext.userInfo.firstName ||
        appContext.userInfo.role === "Guest"
      ) {
        props.setAuthKey("Guest");
        props.setShowAuthenticateModal(true);
      } else if (appContext.cart.CartPrice >= storeContext.orderMin) {
        if (pathParts.length > 2 && pathParts[2] === "rewards") {
          navigate(newPath);
        } else {
          navigate("order");
        }
      } else {
        toast.error(`Mimimum order amount is $${storeContext.orderMin}.`);
      }
    }
  }

  return (
    <div className="card cart_popup fixed-bottom" id="cart_screen">
      {showCart === true && props.detailsAreLoading === false ? (
        <>
          {props.cartOpen ? (
            <div
              className="card-header checkout-card-header"
              onClick={checkout}
            >
              <div className="checkout_container" id="checkout_container">
                <p className="total">
                  Total: ${appContext.cart.CartPrice.toFixed(2)}
                </p>
                <div className="checkout_text">
                  <p className="text">Checkout</p>
                  <BsArrowRightSquareFill />
                </div>
              </div>
            </div>
          ) : (
            <div className="card-header" onClick={toggleCartBody}>
              <div className="viewcart_container">
                <p className="text">View My Cart</p>
                <div className="cart_data">
                  <p className="data">
                    {appContext.cart.CartPrice < 0 ? (
                      <>
                        Total: -$
                        {Math.abs(appContext.cart.CartPrice).toFixed(2)}
                      </>
                    ) : (
                      <>Total: ${appContext.cart.CartPrice.toFixed(2)}</>
                    )}
                  </p>
                  <p className="cartLength">
                    Items: {appContext.cartItems.length}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="card-body collapse" id="cart_popup">
            <div className="cartpopup_container">
              <div className="header_wrapper">
                <h2 className="mycart">My Cart</h2>
                <div className="close_cart">
                  <FaWindowClose onClick={toggleCartBody} />
                </div>
              </div>

              <CartItems
                setSelectedItem={props.setSelectedItem}
                setShowEditItemModal={props.setShowEditItemModal}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                setAppContext={props.setAppContext}
                setCartOpen={props.setCartOpen}
              />
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Cart;
