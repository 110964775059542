import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import "./voidModal.scss";
import AreYouSure from "../areYouSure/AreYouSure";

function VoidAmountModal(props) {
  const [showAreYouSureVoid, setShowAreYouSureVoid] = useState(false);
  const [voidOrderAmount, setVoidOrderAmount] = useState(0.0);

  async function closeVoidModal() {
    var activeButton = document.querySelector(".button.Active");
    if (activeButton) {
      activeButton.classList.remove("Active");
    }
    props.setVoidOrder(false);
  }

  const handleInputVoidChange = (event) => {
    const { value } = event.target;
    if (value) {
      setVoidOrderAmount(parseFloat(value));
    } else {
      setVoidOrderAmount(0.0);
    }
  };

  async function toggleAreYouSure() {
    setShowAreYouSureVoid(true);
  }

  return (
    <>
      {showAreYouSureVoid && (
        <AreYouSure
          showAreYouSure={showAreYouSureVoid}
          setShowAreYouSure={setShowAreYouSureVoid}
          setShowEditOrderScreen={props.setShowEditOrderScreen}
          orderData={props.orderData}
          voidOrderAmount={voidOrderAmount}
          type={"void_amount"}
          getOrderHistory={props.getOrderHistory}
          setVoidOrder={props.setVoidOrder}
        />
      )}
      <Modal className="chargeOrderModal" show={props.voidOrder}>
        <Modal.Body>
          <Modal.Header className="d-flex justify-content-end">
            <FaWindowClose
              onClick={closeVoidModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          <div className="extraCharge">
            <div className="extraCharge_container">
              <div className="charge_inputs">
                <label>How much would you like to Void on this order?</label>
                <div className="input">
                  <div className="money">$</div>
                  <input
                    placeholder="0.00"
                    type="number"
                    onChange={handleInputVoidChange}
                  />
                </div>
              </div>
            </div>

            <div className="button submit" onClick={() => toggleAreYouSure()}>
              Submit
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VoidAmountModal;
